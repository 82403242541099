import React from 'react'

import { FormField, Input, SubmitButton } from '../ui-blocks'


export const LoginForm = ({ fields, isFormValid, isLoading, onSubmit }) => (
  <form onSubmit={e => {
    e.preventDefault()
    onSubmit()
  }}>
    <FormField label="Email" inputField={fields.email}>
      <Input
        id="email"
        type="email"
        autoComplete="email"
        className="rounded-pill"
        {...fields.email.getStatusProps()}
        {...fields.email.getProps()}
      />
    </FormField>

    <FormField label="Password" inputField={fields.password}>
      <Input
        id="password"
        type="password"
        autoComplete="current-password"
        className="rounded-pill"
        {...fields.password.getStatusProps()}
        {...fields.password.getProps()}
      />
    </FormField>
    <SubmitButton
      fullWidth
      isLoading={isLoading}
      isValid={isFormValid}
      type="submit"
    >
      Login
    </SubmitButton>
  </form>
)
