import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useForm, isFormWithHtmlDirty } from '../../helpers'

import { LeavingOnDirtyForm } from '../specialized-components'
import {
  CheckboxGroup, ExternalLinkButton, FormField, Input, RadioButtonGroup, Select, SubmitButton, Textarea
} from '../ui-blocks'

import { ClientList, ClientTypes, ProfilePhoto, ResumeExperienceList, SocialNetworkLists } from './partials'

import {
  consultantFields,
  consultingStatusOptions,
  paymentAcceptedOptions,
  isWaitlistingOptions,
  engagementTypeOptions
} from './consultant-fields'


export const ConsultantProfileEditor = ({ profile, onUpdate }) => {
  const [form, setFormValues, validateForm] = useForm(consultantFields)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setFormValues(profile)
  }, [profile]) // eslint-disable-line react-hooks/exhaustive-deps


  const handleUpdate = async () => {
    const isFormValid = validateForm()

    if (!isFormValid) {
      return
    }

    setIsLoading(true)

    await onUpdate(form.values)

    setIsLoading(false)
  }


  if (!Object.keys(form.values).length) {
    return null
  }


  const isFormDirty = isFormWithHtmlDirty(form)


  return (
    <>
      <LeavingOnDirtyForm isDirty={isFormDirty} />

      <div className="d-xl-flex justify-content-between align-items-center mb-4">
        <h1 className="mb-3 mb-xl-0">Consultant Profile</h1>

        <div className="d-flex flex-grow-1 justify-content-end align-items-center">
          <ExternalLinkButton
            href={profile.link}
            className="mr-3"
            variant="secondary"
            disabled={isFormDirty}
          >
            Preview
          </ExternalLinkButton>
          <SubmitButton
            onClick={handleUpdate}
            isLoading={isLoading}
            isValid={form.isValid}
          >
            Update
          </SubmitButton>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4 col-lg-3 align-self-lg-center text-center">
          <ProfilePhoto
            media={form.values.image}
            setMedia={form.fields.image?.setValue}
            diameter={142}
          />
        </div>

        <div className="col-md-8 col-lg-9">
          <div className="row">
            <div className="col-lg-6">
              <FormField label="First Name" inputField={form.fields.firstName}>
                <Input
                  id="firstName"
                  {...form.fields.firstName?.getStatusProps()}
                  {...form.fields.firstName?.getProps()}
                />
              </FormField>
            </div>
            <div className="col-lg-6">
              <FormField label="Last Name" inputField={form.fields.lastName}>
                <Input
                  id="lastName"
                  {...form.fields.lastName?.getStatusProps()}
                  {...form.fields.lastName?.getProps()}
                />
              </FormField>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <FormField label="Website URL" inputField={form.fields.website}>
                <Input
                  id="website"
                  type="url"
                  {...form.fields.website?.getStatusProps()}
                  {...form.fields.website?.getProps()}
                />
              </FormField>
            </div>
            <div className="col-lg-6">
              <FormField label="Telephone Number" inputField={form.fields.telephone}>
                <Input
                  id="telephone"
                  type="tel"
                  {...form.fields.telephone?.getStatusProps()}
                  {...form.fields.telephone?.getProps()}
                />
              </FormField>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <FormField label="Company Name" inputField={form.fields.company}>
                <Input
                  id="company"
                  {...form.fields.company?.getStatusProps()}
                  {...form.fields.company?.getProps()}
                />
              </FormField>
            </div>

            <div className="col-lg-6">
              <FormField label="Year started consulting" inputField={form.fields.yearStartedConsulting}>
                <Input
                  id="yearStartedConsulting"
                  type="number"
                  {...form.fields.yearStartedConsulting?.getStatusProps()}
                  {...form.fields.yearStartedConsulting?.getProps()}
                />
              </FormField>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <h3 className="h4 mt-3">Introduction</h3>

      <div className="row">
        <div className="col-lg-8">
          <FormField label="Biographical Info" inputField={form.fields.biographicalInfo}>
            <Textarea
              id="biographicalInfo"
              rows="5"
              {...form.fields.biographicalInfo?.getStatusProps()}
              {...form.fields.biographicalInfo?.getProps()}
            />
          </FormField>
        </div>
      </div>

      <h4 className="h5 mt-5">Social Network Profiles</h4>

      <SocialNetworkLists
        networks={form.fields.socialNetworks?.entries}
        setNetworks={form.fields.socialNetworks?.setValue}
      />

      <hr />

      <h3 className="h4 mt-5">Clients</h3>

      <h4 className="h5 mt-3">Past Clients</h4>

      <ClientList
        clients={form.fields.pastClients?.entries}
        setClients={form.fields.pastClients?.setValue}
      />

      <h3 className="h5 mt-3">Client Types</h3>

      <ClientTypes
        clients={form.fields.clientTypes?.entries}
        setClients={form.fields.clientTypes?.setValue}
      />

      <h3 className="h4 mt-5">Availability</h3>

      <div className="row">
        <div className="col-lg-6 col-xl-4">
          <FormField label="Consulting Status" inputField={form.fields.consultingStatus}>
            <RadioButtonGroup
              id="consultingStatus"
              options={consultingStatusOptions}
              {...form.fields.consultingStatus?.getProps()}
            />
          </FormField>
        </div>
        <div className="col-lg-6 col-xl-4">
          <FormField label="Engagement Type" inputField={form.fields.engagementType}>
            <Select
              id="engagementType"
              options={engagementTypeOptions}
              {...form.fields.engagementType?.getStatusProps()}
              {...form.fields.engagementType?.getProps()}
            />
          </FormField>
        </div>
      </div>

      <div className="d-flex">
        <div className="mr-4">
          <FormField label="Are you waitlisting clients?" inputField={form.fields.isWaitlisting}>
            <RadioButtonGroup
              id="isWaitlisting"
              options={isWaitlistingOptions}
              {...form.fields.isWaitlisting?.getProps()}
            />
          </FormField>
        </div>
        {((form.values.isWaitlisting === '1') || !form.fields.waitTime?.isValid) && (
          <FormField label="Wait time in days" inputField={form.fields.waitTime}>
            <Input
              id="waitTime"
              type="number"
              {...form.fields.waitTime?.getStatusProps()}
              {...form.fields.waitTime?.getProps()}
            />
          </FormField>
        )}
      </div>

      <h3 className="h4 mt-5">Payment</h3>

      <div className="row">
        <div className="col-lg-6 col-xl-4">
          <FormField label="Payment Accepted">
            <CheckboxGroup
              id="paymentAccepted"
              options={paymentAcceptedOptions}
              {...form.fields.paymentAccepted?.getProps()}
            />
          </FormField>
        </div>
        <div className="col-lg-6 col-xl-4">
          <FormField label="Minimums" inputField={form.fields.minimums}>
            <Textarea
              id="minimums"
              {...form.fields.minimums?.getStatusProps()}
              {...form.fields.minimums?.getProps()}
            />
          </FormField>
        </div>
      </div>

      <hr />

      <h3 className="h4 mt-5">Resume Experience</h3>

      <ResumeExperienceList
        experiences={form.fields.resumeExperience?.entries}
        setExperiences={form.fields.resumeExperience?.setValue}
      />
    </>
  )
}


ConsultantProfileEditor.propTypes = {
  profile: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired
}
