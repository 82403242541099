import React from 'react'
import PropTypes from 'prop-types'

import { InputField } from '../../../helpers/Form'


const isInputField = inputField => (inputField instanceof InputField)

const isRequired = inputField => {
  if (!isInputField(inputField)) {
    return false
  }

  const validatorNames = inputField.validators.map(validator => validator.name)

  return validatorNames.includes('isRequired')
}


export const FormField = ({
  label,
  labelSuffix,
  isDirty = false,
  isInvalid = false,
  noGutterBottom = false,
  inputField = {},
  className = '',
  children,
  ...props
}) => {
  const inputFieldProps = isInputField(inputField) ? inputField.getStatusProps() : {}

  const labelProps = children.props?.id ? { htmlFor: children.props.id } : {}

  let labelClassName = ''

  if (isDirty || inputFieldProps.isDirty) {
    labelClassName = 'font-weight-bold'
  }

  if (isInvalid || inputFieldProps.isInvalid) {
    labelClassName = 'font-weight-bold text-danger'
  }


  let classes = className

  if (!noGutterBottom) {
    classes += ' mb-3'
  }

  const isRequiredIndicator = isRequired(inputField) ? ' *' : ''


  return (
    <div className={classes} {...props}>
      {(label || labelSuffix) && (
        <div className="d-flex justify-content-between">
          {label &&
            <label className={`form-label d-block ${labelClassName}`} {...labelProps}>
              {label}{isRequiredIndicator}
            </label>
          }
          {labelSuffix && (<span>{labelSuffix}</span>)}
        </div>
      )}
      {children}
    </div>
  )
}


FormField.propTypes = {
  label: PropTypes.string,
  labelSuffix: PropTypes.node,
  isDirty: PropTypes.bool,
  isInvalid: PropTypes.bool,
  gutterBottom: PropTypes.bool,
  inputField: PropTypes.instanceOf(InputField)
}
