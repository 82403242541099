import { isRequired } from '../../helpers/Form'


export default {
  title: {
    validators: [isRequired]
  },
  content: {},
  featuredMedia: {
    value: null
  },
  embedImages: {
    value: []
  }
}
