import React, { useLayoutEffect, useState } from 'react'
import { useHistory, useLocation, useParams, Link, Redirect } from 'react-router-dom'

import { EditLesson as EditLessonForm } from '../components/Lessons'

import { useAsyncError } from '../components/error-handling'
import { getConfirmDelete } from '../components/specialized-components'
import { icons, Icon, Loader } from '../components/ui-blocks'

import { useModal } from '../contexts'

import { getById, getParsedFormData } from '../helpers'
import { getLesson, createLesson, updateLesson, deleteLesson } from '../helpers/consultdex-wp-api'


export const EditLesson = () => {
  const id = Number(useParams().id)

  const [editingCourse, setEditingCourse] = useState(null)
  const [lesson, setLesson] = useState(null)
  const { openModal } = useModal()
  const history = useHistory()
  const location = useLocation()

  const handleAsyncError = useAsyncError()


  useLayoutEffect(() => {
    if (!id) {
      setLesson({})
      return
    }

    getLesson(id)
      .then(results => {
        setLesson(results)

        const courseId = Number(new URLSearchParams(location.search).get('courseId'))
        setEditingCourse(getById(courseId, results.courses))

      }, handleAsyncError)
  }, [id, handleAsyncError, location.search])


  const handleUpdate = async values => {
    if (lesson?.id) {
      setLesson(await updateLesson(lesson?.id, getParsedFormData(values)))
      return
    }

    const createdLesson = await createLesson(getParsedFormData(values))
    setLesson(createdLesson)
  }


  if (lesson?.id && (id !== lesson.id)) {
    return (<Redirect to={`/lessons/${lesson.id}`} />)
  }


  const handleDelete = async () => {
    if (await openModal(
      getConfirmDelete(<>You will delete the lesson <strong>{lesson.title}</strong>.</>)
    )) {
      await deleteLesson(lesson.id)

      history.push('/lessons/')
    }
  }


  return (
    <>
      {editingCourse ? (
        <Link to={`/courses/${editingCourse.id}`} className="text-dark mb-3 d-inline-block underline-on-hover">
          <Icon icon={icons.back} className="mr-2" /><strong>{editingCourse.title}</strong> Course
        </Link>
      ) : (
        <Link to="/lessons/" className="text-dark mb-3 d-inline-block underline-on-hover">
          <Icon icon={icons.back} className="mr-2" />Back to Lessons
        </Link>
      )}

      {lesson ?
        <EditLessonForm
          lesson={lesson}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
        />
        : <Loader fullWidth />
      }
    </>
  )

}
