import { areObjectEquals } from './object'
import { getNormalizedHtml } from './string'


export const isFormWithHtmlDirty = form => !areObjectEquals(
  {
    ...form.initialValues,
    content: getNormalizedHtml(form.initialValues?.content)
  },
  {
    ...form.values,
    content: getNormalizedHtml(form.values?.content)
  }
)
