import React from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'

import { getMediaElementFromBrowserFile } from './media-element'


const maxFileSizeInMegabytes = 2


export const ImageSelector = ({ setMedia, trigger, notifyInvalidFile = () => { } }) => {

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png',
    maxSize: maxFileSizeInMegabytes * 1000000,
    multiple: false,
    onDrop: (selectedFiles, rejectedFiles) => {
      rejectedFiles.forEach(file => {
        file.errors.forEach(err => {
          if (err.code === 'file-too-large') {
            notifyInvalidFile(`File is bigger than the maximum size allowed (${maxFileSizeInMegabytes} MB)`)
            return
          }

          notifyInvalidFile(err.message)
        })
      })

      const mediaElements = selectedFiles.map(getMediaElementFromBrowserFile)

      setMedia(mediaElements.length ? mediaElements[0] : null)
    }
  })


  return (
    <div {...getRootProps()} className="text-center">
      <input {...getInputProps()} />
      {trigger}
    </div>
  )
}


ImageSelector.propTypes = {
  setMedia: PropTypes.func.isRequired,
  notifyInvalidFile: PropTypes.func
}
