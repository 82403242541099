import { useEffect, useReducer } from 'react'

import { createField } from './field-helper'


function getValues(fields) {
  const values = {}

  Object.keys(fields).forEach(key => {
    values[key] = fields[key].value
  })

  return values
}


function validateFields(fields) {
  let isValid = true

  Object.keys(fields).forEach(key => {
    fields[key].validate()
    isValid = isValid && fields[key].isValid
  })

  return isValid
}


function reducer(state, action) {
  switch (action.type) {
    case 'setInitial':
      const fields = {}
      const { fieldOptions, handleChange } = action.payload

      Object.keys(fieldOptions).forEach(key => {

        fields[key] = createField({
          ...fieldOptions[key],
          onChange: handleChange
        })
      })

      return {
        ...state,
        fields,
        values: getValues(fields)
      }

    case 'setValues':
      const updatedFields = { ...state.fields }

      Object.keys(state.fields).forEach(key => {
        if (typeof action.payload.values[key] !== 'undefined') {
          updatedFields[key] = createField({
            ...action.payload.fieldOptions[key],
            value: action.payload.values[key],
            onChange: action.payload.handleChange
          })
        }
      })

      const values = getValues(updatedFields)

      return {
        ...state,
        fields: updatedFields,
        values,
        initialValues: values,
      }

    case 'refresh':
      return {
        ...state,
        values: getValues(state.fields),
        isValid: validateFields(state.fields)
      }


    case 'setValidity':
      return {
        ...state,
        isValid: action.payload
      }

    default:
      throw new Error()
  }
}


export const useForm = fieldOptions => {

  const [form, dispatch] = useReducer(reducer, {
    fields: {},
    values: {},
    initialValues: {},
    isValid: true
  })

  const handleChange = () => dispatch({ type: 'refresh' })

  useEffect(() => {
    dispatch({
      type: 'setInitial', payload: {
        fieldOptions,
        handleChange
      }
    })
  }, [fieldOptions])


  return [
    form,
    values => dispatch({
      type: 'setValues',
      payload: {
        fieldOptions,
        handleChange,
        values
      }
    }),
    () => {
      const isFormValid = validateFields(form.fields)

      dispatch({ type: 'setValidity', payload: isFormValid })

      return isFormValid
    }
  ]
}
