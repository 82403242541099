import React, { useEffect, useState } from 'react'

import { Loader } from '../components/ui-blocks'
import { getConfirmDelete } from '../components/specialized-components'

import { Courses as CoursesComponent } from '../components/Courses'

import { useModal } from '../contexts'

import { getCourses, deleteCourse } from '../helpers/consultdex-wp-api'


export const Courses = () => {
  const [courses, setCourses] = useState()
  const { openModal } = useModal()


  const loadCourses = () => {
    getCourses()
      .then(results => {
        setCourses(results)
      })
  }

  useEffect(loadCourses, [])


  const deleteAction = async row => {
    const isConfirmed = await openModal(
      getConfirmDelete(<>You will delete the course <strong>{row.title}</strong>.</>)
    )

    if (!isConfirmed) {
      return
    }

    await deleteCourse(row.id)

    loadCourses()
  }


  return (
    <>
      <h1>Courses</h1>
      {!!courses ? (
        <CoursesComponent courses={courses} deleteAction={deleteAction} />
      ) : (
          <Loader fullWidth />
        )}
    </>
  )
}
