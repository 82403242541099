import React, { useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { getAvatar } from '../../helpers/consultdex-wp-api'
import { useUser } from '../../contexts'

import { AvatarMenu } from '../specialized-components'
import { icons, IconButton } from '../ui-blocks'


export const TopBar = ({ avatarMenuEntries, setIsSidebarVisible }) => {
  const { userAvatar, setUserAvatar, role, logoutUser } = useUser()


  useLayoutEffect(() => {
    if (!userAvatar) {
      getAvatar()
        .then(avatar => {
          setUserAvatar(avatar)
        })
    }
  })


  const avatarMenuEntriesNode = avatarMenuEntries.map((entry, index) => (
    <li key={index}>
      <Link to={entry.route} className="dropdown-item">
        {entry.label}
      </Link>
    </li>
  ))


  return (
    <nav className="topbar-wrapper px-lg-5 position-fixed">
      <div className="topbar-content bg-light px-2 px-lg-0 mx-auto d-flex justify-content-between">
        <div>
          <IconButton
            icon={icons.menu}
            onClick={() => setIsSidebarVisible(true)}
            className="lead mt-1 d-lg-none"
          />
        </div>
        <AvatarMenu
          avatarProps={userAvatar}
          role={role}
          menuEntries={avatarMenuEntriesNode}
          handleLogout={logoutUser}
        />
      </div>
    </nav>
  )
}


TopBar.propTypes = {
  avatarMenuEntries: PropTypes.array.isRequired,
  setIsSidebarVisible: PropTypes.func.isRequired
}
