import React from 'react'
import PropTypes from 'prop-types'

import { icons, Icon, IconButton, Input } from '../../ui-blocks'


export const networks = [
  {
    id: 'linkedin',
    label: 'LinkedIn',
    icon: icons.linkedin
  },
  {
    id: 'twitter',
    label: 'Twitter',
    icon: icons.twitter
  },
  {
    id: 'instagram',
    label: 'Instagram',
    icon: icons.instagram
  },
  {
    id: 'facebook',
    label: 'Facebook',
    icon: icons.facebook
  },
  {
    id: 'wikipedia',
    label: 'Wikipedia',
    icon: icons.wikipedia
  },
  {
    id: 'snapchat',
    label: 'Snapchat',
    icon: icons.snapchat
  }
]


export const SocialNetworkLinkInput = ({
  item,
  dragHandleProps,
  handleDelete
}) => {
  const network = networks.find(networkEntry => networkEntry.id === item.network.value)

  if (!network) {
    return null
  }

  return (
    <div className="pb-1 d-flex align-items-center">
      <div className="mr-1 px-1" {...dragHandleProps}>
        <Icon icon={icons.verticalDragHandle} />
      </div>
      <div className="text-right mr-2 form-label" style={{ minWidth: '6rem' }}>
        {network.label}
      </div>
      <div className="flex-grow-1">
        <Input
          prefix={
            <Icon icon={network.icon} style={{ textAlign: 'center', width: '1rem' }} />
          }
          type="url"
          {...item.url.getStatusProps()}
          {...item.url.getProps()}
        />
      </div>
      {handleDelete && (
        <div className="ml-1 px-1">
          <IconButton
            icon={icons.removeCircle}
            onClick={() => handleDelete(item.id)}
            className="faded danger-on-hover"
          />
        </div>
      )}
    </div>
  )
}


SocialNetworkLinkInput.propTypes = {
  item: PropTypes.object.isRequired,
  dragHandleProps: PropTypes.object,
  handleDelete: PropTypes.func
}
