const videoProviderMatchers = {
  YouTube: /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)(?<id>[\w-]+)(\S+)?$/
}


export const getVideoProvider = url => {
  const matched = Object.entries(videoProviderMatchers)
    .find(([name, regex]) => regex.test(url))

  if (matched) {
    return matched[0]
  }

  return false
}


export const getYouTubeVideoId = youTubeUrl =>
  String(youTubeUrl).match(videoProviderMatchers.YouTube)?.groups?.id


export const getYouTubeThumbnailUrl = youTubeId => `https://img.youtube.com/vi/${youTubeId}/mqdefault.jpg`


export const isYouTubeVideo = url => getVideoProvider(url) === 'YouTube'
