import { stateFromHTML } from 'draft-js-import-html'
import { stateToHTML } from 'draft-js-export-html'

/**
 * Used to compare Content avoiding HTML Formatting Discrepancies
 * between WordPress and Draft.js Editor
 */
export const getNormalizedHtml = html =>
  stateToHTML(stateFromHTML(html))


/**
 * returns String containing only a-z and 0-9 Characters
 */
export const getFuzzy = string =>
  String(string).trim().toLowerCase().replace(/[^a-z0-9]/gi, '')


export const includesFuzzy = (haystack, needle) =>
  getFuzzy(haystack).includes(needle)
