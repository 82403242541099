import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { useOutsideClick } from '../..'

import { ModalBackDrop } from './ModalBackDrop'
import { ModalDialog } from './ModalDialog'


export const Modal = ({ isOpen, onClose, ...props }) => {

  const wrapperRef = useRef(null)
  const modalContentRef = useRef(null)

  useOutsideClick(modalContentRef, () => onClose(null))

  useEffect(() => {
    if (isOpen && wrapperRef?.current) {
      wrapperRef.current.focus()
    }
  }, [isOpen])


  const handleKeyDown = event => {
    if (['Escape'].includes(event.key)) {
      onClose(null)
    }
  }


  if (!isOpen) {
    return null
  }

  return (<>
    <div className="modal d-block" tabIndex="-1" ref={wrapperRef} onKeyDown={handleKeyDown}>
      <ModalDialog onClose={onClose} contentRef={modalContentRef} {...props} />
    </div>
    <ModalBackDrop />
  </>)
}


Modal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
}
