export const getPurgedEmbedImages = (content, embedImages) =>
  embedImages.filter(image =>
    content.includes(`src="${image.previewUrl}"`)
  )


export const getParsedFormData = values => {
  const formData = new FormData()

  parseFormData(formData, values)

  return formData
}


function parseFormData(formData, data, parentKey) {
  if (data
    && typeof data === 'object'
    && !(data instanceof Date)
    && !(data instanceof File)
    && !(data instanceof Blob)) {

    Object.keys(data).forEach(key => {
      parseFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
    })

  } else {
    const value = data == null ? '' : data

    formData.append(parentKey, value)
  }
}
