import React from 'react'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { getArrayWithRelocatedEntry } from '../../helpers'


export const SortableList = ({ id, items, setItems, itemRenderer }) => {

  const handleDragEnd = result => {
    if (!result.destination) {
      return
    }

    setItems(getArrayWithRelocatedEntry(
      items,
      result.source.index,
      result.destination.index
    ))
  }


  return (
    <DragDropContext onDragEnd={handleDragEnd}>

      <Droppable droppableId={id} type="topics">
        {provided => (
          <div ref={provided.innerRef}>
            {items.map((item, index) => (
              <Draggable key={String(item.id)} draggableId={String(item.id)} index={index}>
                {draggableProvided => (
                  <>
                    <div
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                    >
                      {itemRenderer({
                        item: item,
                        dragHandleProps: draggableProvided.dragHandleProps
                      })}
                    </div>
                    {draggableProvided.placeholder}
                  </>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

    </DragDropContext>
  )
}


SortableList.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.isRequired
  })).isRequired,
  setItems: PropTypes.func.isRequired,
  itemRenderer: PropTypes.func.isRequired
}
