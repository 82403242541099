/**
 * @param {string[]} userPermissions
 */
export const createHasPermissions = userPermissions => requiredPermissions => {

  const permissionsFound = requiredPermissions.filter(permission => userPermissions.includes(permission))

  return permissionsFound.length === requiredPermissions.length
}


/**
 * @param {string[]} userPermissions
 * @param {object} availableRoles
 */
export const getUserRole = (userPermissions, availableRoles) =>
  Object.values(availableRoles).find(role => userPermissions.includes(role))
