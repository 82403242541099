import { isRequired, isIntegerBetween, isValidBasicUrl, IdField, FieldList } from '../../helpers/Form'


const minimumAllowedYear = 1940
const currentYear = new Date().getFullYear()

const yearValidator = isIntegerBetween(minimumAllowedYear, currentYear)


export const consultantFields = {
  firstName: {
    validators: [isRequired]
  },
  lastName: {
    validators: [isRequired]
  },
  link: {},
  image: {
    value: null
  },
  website: {
    validators: [isRequired, isValidBasicUrl]
  },
  company: {
    validators: [isRequired]
  },
  telephone: {
    validators: [isRequired]
  },
  yearStartedConsulting: {
    validators: [isRequired, yearValidator]
  },
  biographicalInfo: {
    validators: [isRequired]
  },
  socialNetworks: {
    factoryClass: FieldList,
    fields: {
      id: {
        factoryClass: IdField
      },
      network: {
      },
      url: {
        validators: [isRequired, isValidBasicUrl]
      }
    }
  },
  streamingChannel: {},
  pastClients: {
    factoryClass: FieldList,
    fields: {
      id: {
        factoryClass: IdField
      },
      title: {
        validators: [isRequired]
      },
      link: {
        validators: [isRequired, isValidBasicUrl]
      }
    }
  },
  clientTypes: {
    factoryClass: FieldList,
    fields: {
      id: {
        factoryClass: IdField
      },
      item: {
        validators: [isRequired]
      }
    }
  },
  consultingStatus: {},
  isWaitlisting: {
    value: '0'
  },
  waitTime: {
    validators: [isIntegerBetween(1, 365)]
  },
  engagementType: {},
  paymentAccepted: {},
  minimums: {},
  resumeExperience: {
    factoryClass: FieldList,
    fields: {
      id: {
        factoryClass: IdField
      },
      title: {
        validators: [isRequired]
      },
      /* eslint-disable-next-line camelcase */
      company_name: {
      },
      description: {
      },
      location: {
      },
      /* eslint-disable-next-line camelcase */
      start_date: {
        validators: [yearValidator]
      },
      /* eslint-disable-next-line camelcase */
      end_date: {
        validators: [yearValidator]
      },
    }
  }
}


export const consultingStatusOptions = [
  { value: 'Part Time', label: 'Part Time' },
  { value: 'Full Time', label: 'Full Time' }
]


export const paymentAcceptedOptions = [
  { value: 'Equity', label: 'Equity' },
  { value: 'Performance', label: 'Performance' },
  { value: 'Hourly', label: 'Hourly' },
  { value: 'Monthly', label: 'Monthly' }
]


export const isWaitlistingOptions = [
  { value: '0', label: 'No' },
  { value: '1', label: 'Yes' }
]


export const engagementTypeOptions = [
  { value: 'Retainer Only', label: 'Retainer Only' },
  { value: 'Project', label: 'Project' },
  { value: 'Project or Retainer', label: 'Project or Retainer' }
]
