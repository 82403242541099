import { getUserTokenFromCookie, setUserTokenCookie, redirectToLogin } from './auth'


export const login = credentials => apiFetch('/jwt/v1/auth/', credentials)

export const getAvatar = () => apiFetch('/members/avatar/')

export const getConsultantProfile = () => apiFetch('/consultants/profile/')

export const updateConsultantProfile = payload => apiFetch('/consultants/profile/', payload)


export const getCourses = () => apiFetch('/consultants/courses/')

export const getCourse = id => apiFetch(`/consultants/courses/${id}`)

export const createCourse = payload => apiFetch('/consultants/courses/', payload)

export const updateCourse = (id, payload) => apiFetch(`/consultants/courses/${id}`, payload)

export const deleteCourse = id => apiFetch(`/consultants/courses/${id}`, null, 'DELETE')

export const getAvailableSpecialties = () => apiFetch('/consultants/courses/specialties/')


export const getLessons = () => apiFetch('/consultants/lessons/')

export const getLesson = id => apiFetch(`/consultants/lessons/${id}`)

export const createLesson = payload => apiFetch('/consultants/lessons/', payload)

export const updateLesson = (id, payload) => apiFetch(`/consultants/lessons/${id}`, payload)

export const deleteLesson = id => apiFetch(`/consultants/lessons/${id}`, null, 'DELETE')


export const getTopics = () => apiFetch('/consultants/topics/')

export const getTopic = id => apiFetch(`/consultants/topics/${id}`)

export const createTopic = payload => apiFetch('/consultants/topics/', payload)

export const updateTopic = (id, payload) => apiFetch(`/consultants/topics/${id}`, payload)

export const deleteTopic = id => apiFetch(`/consultants/topics/${id}`, null, 'DELETE')


export async function apiFetch(endpoint, payload, method = 'GET') {
  const isFormDataPayload = payload instanceof FormData

  const options = payload ? {
    method: 'POST',
    body: isFormDataPayload ? payload : JSON.stringify(payload)
  } : {}


  const headers = isFormDataPayload ? {} : {
    'Content-Type': 'application/json'
  }

  const token = getUserTokenFromCookie()

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }


  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_WP_API_URL}/wp-json${endpoint}`, {
      method,
      ...options,
      headers
    })
      .then(async response => {
        if (response.ok) {
          resolve(response.json())
          return
        }

        if (response.status === 404) {
          reject(response)
          return
        }


        let body

        try {
          body = response.body ? await response.json() : null
        } catch (e) {
          reject(response)
        }

        if ((response.status === 401) ||
          ((response.status === 400) && (body.data?.errorCode === 14))) {
          setUserTokenCookie('')
          redirectToLogin()
          return
        }

        reject(response)
      })
  })

}
