import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { getUpdatedArray } from '../../../helpers'

import { Button } from '../../ui-blocks'

import { CourseSchedule } from './CourseSchedule'
import { StepsTable } from './StepsTable'


export const CourseBuilder = ({ courseId, sections, setSections, availableLessons, availableTopics }) => {

  const lessons = sections.filter(section => section.children)
  const firstLesson = lessons.length ? lessons[0] : null

  const [lastEditedLessonId, setLastEditedLessonId] = useState(firstLesson?.id)


  const topicsInCourse = sections
    .map(section => section.children)
    .filter(Boolean)
    .flat()


  const addLessonToCourse = lesson => {
    setLastEditedLessonId(lesson.id)

    setSections([
      ...sections,
      {
        children: [],
        ...lesson
      }
    ])
  }


  const addTopicToLesson = topic => {
    const lessonToUpdate = (lessons.find(lesson => lesson.id === lastEditedLessonId))
      || firstLesson

    if (!lessonToUpdate) {
      return
    }

    const updatedLesson = {
      ...lessonToUpdate,
      children: [
        ...lessonToUpdate.children,
        { ...topic }
      ]
    }

    setSections(getUpdatedArray(sections, updatedLesson))
  }


  const handleAddNewLesson = () => {
    addLessonToCourse({
      id: Date.now(),
      title: '',
      link: null,
      type: 'new-lesson'
    })
  }


  const handleAddNewTopic = () => {
    addTopicToLesson({
      id: Date.now(),
      title: '',
      link: null,
      type: 'new-topic'
    })
  }


  return (
    <>
      <h5 className="card-title">Course Schedule</h5>

      {(sections.length) ? (
        <>
          <CourseSchedule
            courseId={courseId}
            sections={sections}
            setSections={setSections}
            lastEditedLessonId={lastEditedLessonId}
            setLastEditedLessonId={setLastEditedLessonId}
          />
          <div className="d-flex justify-content-end mt-2 mb-5">
            <Button onClick={handleAddNewLesson} className="mr-2">Add Lesson</Button>
            <Button onClick={handleAddNewTopic} disabled={!firstLesson}>Add Topic</Button>
          </div>
        </>
      ) : (
        <div className="bg-white rounded-lg p-5 text-center mb-5">
          <p className="h4">
            This area contains the different sections of your course.
          </p>
          <p className="lead">
            You can start creating your first lesson now.
          </p>
          <Button onClick={handleAddNewLesson} className="mr-2">Add Lesson</Button>
        </div>
      )}

      <StepsTable
        label="Lessons"
        steps={availableLessons}
        courseSteps={sections}
        onAddToCourse={addLessonToCourse}
      />

      <StepsTable
        label="Topics"
        steps={availableTopics}
        courseSteps={topicsInCourse}
        onAddToCourse={firstLesson ? addTopicToLesson : null}
      />
    </>
  )
}


CourseBuilder.propTypes = {
  courseId: PropTypes.number.isRequired,
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSections: PropTypes.func.isRequired,
  availableLessons: PropTypes.arrayOf(PropTypes.object),
  availableTopics: PropTypes.arrayOf(PropTypes.object)
}
