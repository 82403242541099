import React from 'react'
import PropTypes from 'prop-types'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { Link } from 'react-router-dom'

import { CoursePart, InlineEdit } from '../../specialized-components'
import { icons, IconButton } from '../../ui-blocks'


const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightgreen' : 'transparent'
})


export const DraggableTopics = ({ courseId, lesson, onChangeTopicTitleInLesson, onRemoveTopicFromLesson }) => {

  const getCoursePartProps = topic => {
    const commonsProps = {
      type: 'topic',
      title: topic.title,
      actions: (
        <big>
          <IconButton
            icon={icons.removeCircle}
            variant="danger"
            onClick={() => onRemoveTopicFromLesson(topic, lesson)}
          />
        </big>
      )
    }

    if (topic.type !== 'new-topic') {
      return {
        ...commonsProps,
        actions: (<>
          <Link to={`/topics/${topic.id}?courseId=${courseId}`} className="mr-1">
            <big>
              <IconButton icon={icons.edit} className="link-dark faded" />
            </big>
          </Link>
          {commonsProps.actions}
        </>)
      }
    }


    return {
      ...commonsProps,
      title: (<InlineEdit
        value={topic.title}
        onChange={newTitle => onChangeTopicTitleInLesson(lesson, topic, newTitle)}
        placeholder="Enter topic title"
        autoFocus={!topic.title}
      />),
      isNew: true,
      className: 'highlight-actions-on-hover'
    }
  }


  return (
    <Droppable droppableId={String(lesson.id)} type="topics">
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={getListStyle(snapshot.isDraggingOver)}
          className="pt-2"
        >
          {lesson.children.map((topic, index) => (
            <Draggable key={topic.id} draggableId={String(topic.id)} index={index}>
              {draggableProvided => (
                <>
                  <div
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    className="pb-2"
                  >
                    <CoursePart
                      {...getCoursePartProps(topic)}
                      dragHandleProps={draggableProvided.dragHandleProps}
                    />
                  </div>
                  {draggableProvided.placeholder}
                </>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}


DraggableTopics.propTypes = {
  courseId: PropTypes.number.isRequired,
  lesson: PropTypes.object.isRequired,
  onChangeTopicTitleInLesson: PropTypes.func.isRequired,
  onRemoveTopicFromLesson: PropTypes.func.isRequired
}
