const cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN
const tokenKey = process.env.REACT_APP_USER_TOKEN_KEY


export const decodeJwt = token => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}


export const getUserTokenFromCookie = () => getCookieValue(tokenKey)


export const redirectToLogin = () => {
  if (isCurrentlyOnLogin()) {
    return
  }

  window.location.assign(getLoginUrlWithRedirection(window.location.href))
}


export const followRedirectUrl = () => {
  window.location.replace(getRedirectionUrl(window.location.href))
}


export const getFrontEndUrlFromRedirection = () => {
  const redirectionUrl = getRedirectionUrl(window.location.href)
  const frontEndUrl = process.env.REACT_APP_WP_API_URL

  if (redirectionUrl.startsWith(frontEndUrl)) {
    return redirectionUrl
  }

  return frontEndUrl
}


export function isCurrentlyOnLogin() {
  return window.location.href.startsWith(getLoginUrl())
}


export function setUserTokenCookie(token) {
  document.cookie = `${tokenKey}=${token};path=/;max-age=31536000;domain=${cookieDomain}`
}


/** helper for Unit Testing */
export const privateFunctions = {
  getLoginUrlWithRedirection,
  getRedirectionUrl
}


function getLoginUrlWithRedirection(redirectionUrl) {
  const loginUrl = new URL(getLoginUrl())

  const skipRedirectionUrls = [
    getLoginUrl(),
    getPublicUrl()
  ]


  if (redirectionUrl && !skipRedirectionUrls.includes(redirectionUrl)) {
    loginUrl.searchParams.set('redirect', redirectionUrl)
  }

  return loginUrl.toString()
}


function getRedirectionUrl(url) {
  const redirectionUrl = (new URL(url)).searchParams.get('redirect')

  if (redirectionUrl) {
    return redirectionUrl
  }

  return getPublicUrl()
}


function getLoginUrl() {
  return `${getPublicUrl()}/login`
}


function getPublicUrl() {
  if (process.env.PUBLIC_URL) {
    return process.env.PUBLIC_URL
  }

  return window.location.origin
}


function getCookieValue(cookieName) {
  const b = document.cookie.match(`(^|;)\\s*${cookieName}\\s*=\\s*([^;]+)`)

  return b ? b.pop() : ''
}
