import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useForm, isFormWithHtmlDirty } from '../../helpers'

import { HtmlEditor, LeavingOnDirtyForm, MediaSelector, SpecialtiesSelector } from '../specialized-components'
import { icons, Button, Card, ExternalLinkButton, FormField, Icon, Input, Select, SubmitButton } from '../ui-blocks'

import { CourseBuilder } from './CourseBuilder'

import { courseFields, levelOptions, statusOptions, teamSizeOptions } from './course-fields'


export const EditCourse = ({ course, lessons, topics, availableSpecialties, onUpdate, onDelete }) => {
  const [form, setFormValues, validateForm] = useForm(courseFields)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setFormValues(course)
  }, [course]) // eslint-disable-line react-hooks/exhaustive-deps


  const handleUpdate = async () => {
    const isFormValid = validateForm()

    if (!isFormValid) {
      return
    }

    setIsLoading(true)

    await onUpdate(form.values)

    setIsLoading(false)
  }


  if (!Object.keys(form.values).length) {
    return null
  }


  const isNew = !course.id
  const isFreeCourse = !!String(form.fields.price.value)
    && form.fields.price.isValid
    && (Number(form.fields.price.value) === 0)

  const isFormDirty = isFormWithHtmlDirty(form)


  return (
    <>
      <LeavingOnDirtyForm isDirty={isFormDirty} />

      <div className="d-xl-flex justify-content-between align-items-center mb-4">
        <h1 className="mb-3 mb-xl-0">{isNew ? 'New Course' : 'Edit Course'}</h1>

        <div className="d-sm-flex flex-grow-1 justify-content-end align-items-center">
          <div className="mb-2 mb-sm-0 mr-sm-3">
            <Select
              options={statusOptions}
              {...form.fields.status.getStatusProps()}
              {...form.fields.status.getProps()}
            />
          </div>
          <div className="text-right">
            {!isNew && (
              <ExternalLinkButton
                href={course.link}
                className="mr-3"
                variant="secondary"
                disabled={isFormDirty}
              >
                Preview
              </ExternalLinkButton>
            )}
            <SubmitButton
              onClick={handleUpdate}
              isLoading={isLoading}
              isValid={form.isValid}
            >
              {isNew ? 'Create' : 'Update'}
            </SubmitButton>
          </div>
        </div>
      </div>

      <Input
        className="mb-5 border-0 form-control-lg paper"
        placeholder="Insert title..."
        {...form.fields.title?.getStatusProps()}
        {...form.fields.title?.getProps()}
      />

      <div className="row mb-5">
        <div className="col-xl-8">

          <Card title="Course Description" className="mb-5">
            <HtmlEditor {...form.fields.content?.getProps()} />
          </Card>

          {!isNew && (
            <CourseBuilder
              courseId={course.id}
              sections={form.fields.sections.value}
              setSections={form.fields.sections.setValue}
              availableLessons={lessons}
              availableTopics={topics}
            />
          )}

        </div>

        <div className="col-xl-4">

          <div className="mb-5 mb-3">
            <div className="d-flex justify-content-xl-end align-items-center">
              <label className="form-label d-block mb-0 mr-1" for="price">Price:</label>
              <span>
                {isFreeCourse ? (
                  <span className="badge rounded-pill bg-success">Free Course</span>
                ) : (
                  <span>USD {form.fields.price.value}</span>
                )}
              </span>
            </div>
          </div>

          <div className="mb-5">
            <MediaSelector
              media={form.fields.featuredMedia.value}
              setMedia={form.fields.featuredMedia.setValue}
            />
            {!form.fields.featuredMedia.isValid && (
              <div className="text-center text-danger">
                Please select a Featured Media
              </div>
            )}
          </div>

          <Card title="Requirements" className="mb-5">
            <FormField label="Team Size" inputField={form.fields.teamSize}>
              <Select
                id="teamSize"
                options={teamSizeOptions}
                {...form.fields.teamSize.getStatusProps()}
                {...form.fields.teamSize.getProps()}
              />
            </FormField>

            <FormField label="Skill Level" inputField={form.fields.level}>
              <Select
                id="level"
                options={levelOptions}
                {...form.fields.level.getStatusProps()}
                {...form.fields.level.getProps()}
              />
            </FormField>
          </Card>

          <Card title="Specialties" className="mb-5">
            <SpecialtiesSelector
              specialties={form.fields.specialties.value}
              setSpecialties={form.fields.specialties.setValue}
              availableSpecialties={availableSpecialties}
            />
          </Card>

        </div>
      </div>

      {!isNew && (
        <span className="text-muted">
          <Button
            onClick={onDelete}
            variant="outline-danger"
            className="btn-danger-on-hover mr-3 btn-sm px-3"
          >
            <Icon icon={icons.delete} className="mr-2" />
            Delete this Course
          </Button>
        </span>
      )}

    </>
  )
}


EditCourse.propTypes = {
  course: PropTypes.object,
  lessons: PropTypes.arrayOf(PropTypes.object),
  topics: PropTypes.arrayOf(PropTypes.object),
  availableSpecialties: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}
