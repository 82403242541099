import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Button, Card } from '../../ui-blocks'

import { ImageSelector } from './ImageSelector'
import { VideoSelector } from './VideoSelector'

import { mediaElementPropTypes } from './media-element'


const isUsingVideo = mediaType => mediaType === 'YouTube'


export const MediaSelector = ({ media, setMedia }) => {
  const [showUseVideo, setShowUseVideo] = useState(isUsingVideo(media?.type))

  useEffect(() => {
    setShowUseVideo(isUsingVideo(media?.type))
  }, [media])

  const imageSelectorLabel = (showUseVideo || !media) ? 'Use Image' : 'Replace Image'


  return (
    <Card title="Featured Media" imageProps={media?.previewUrl ? {
      src: media?.previewUrl,
      alt: 'Media Preview'
    } : {}}>
      <div className="d-flex justify-content-around">
        <ImageSelector
          setMedia={setMedia}
          trigger={(<Button className="btn-sm px-3">
            {imageSelectorLabel}
          </Button>)}
        />
        {!showUseVideo && (
          <Button className="btn-sm px-3" onClick={() => setShowUseVideo(true)}>
            Use Video
          </Button>
        )}
      </div>
      {showUseVideo && (
        <div className="mt-3">
          <VideoSelector media={media} setMedia={setMedia} />
        </div>
      )}
    </Card>
  )
}


MediaSelector.propTypes = {
  media: mediaElementPropTypes,
  setMedia: PropTypes.func.isRequired
}
