import { getArrayWithRelocatedEntry, getUpdatedArray } from '../../../helpers'


export const getSectionsBasedOnDragAndDropAction = (sections, action) => {

  const sourceIndex = action.source.index
  const destIndex = action.destination.index

  const sourceParentId = action.source.droppableId
  const destParentId = action.destination.droppableId


  if ((sourceParentId === 'main') && (destParentId === 'main')) {
    return getArrayWithRelocatedEntry(sections, sourceIndex, destIndex)
  }


  if (sourceParentId === destParentId) {
    const sectionId = Number(sourceParentId)

    const sectionToUpdate = sections.find(section => section.id === sectionId)

    return getUpdatedArray(sections, {
      ...sectionToUpdate,
      children: getArrayWithRelocatedEntry(
        sectionToUpdate.children,
        sourceIndex,
        destIndex
      )
    })
  }


  const sourceSectionId = Number(sourceParentId)
  const sourceSection = { ...sections.find(section => section.id === sourceSectionId) }
  const [draggedItem] = sourceSection.children.splice(sourceIndex, 1)

  let updatedSections = getUpdatedArray(sections, sourceSection)


  const destSourceSectionId = Number(destParentId)
  const destSection = { ...sections.find(section => section.id === destSourceSectionId) }
  destSection.children.splice(destIndex, 0, draggedItem)

  updatedSections = getUpdatedArray(updatedSections, destSection)

  return updatedSections
}
