const areObjectEquals = (objectA, objectB) =>
  JSON.stringify(objectA) === JSON.stringify(objectB)


export class InputField {

  constructor(properties = {}) {
    const { value, onChange, validators, ...attributes } = properties

    this.attributes = attributes
    this.onChange = onChange || (() => { })
    this.validators = validators || []

    this.value = value

    this.touched = false
    this.isDirty = false
    this.isValid = true
  }


  getProps = () => ({
    value: this.value,
    onBlur: this.onBlur,
    onChange: this.handleChange
  })


  getStatusProps = () => ({
    isDirty: this.isDirty,
    isInvalid: !this.isValid
  })


  get value() {
    return this.internalValue
  }


  set value(value) {
    this.internalValue = (typeof value === 'undefined') ? '' : value

    if ((typeof value !== 'undefined') && (typeof this.initialValue === 'undefined')) {
      this.initialValue = this.internalValue
    }
  }


  setValue = value => {
    this.value = value

    this.isDirty = !areObjectEquals(this.value, this.initialValue)

    if (this.touched) {
      this.validate()
    }

    this.onChange(this)
  }


  handleChange = (input, value) => {
    if ((typeof value !== 'undefined') && (typeof value !== 'object')) {
      this.setValue(String(value))
      return
    }

    if (value === null) {
      this.setValue('')
      return
    }

    if (input && input.target) {
      this.setValue(input.target.value)
      return
    }

    this.setValue(input)
  }


  onBlur = () => {
    this.touched = true
    this.validate()
    this.onChange(this)
  }


  validate = () => {
    if (!this.validators.length) {
      this.isValid = true
      return
    }

    this.isValid = !this.validators.find(validator => !validator(this.value))
  }

}
