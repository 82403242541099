import React, { useEffect, useState } from 'react'

import { Loader } from '../components/ui-blocks'

import { ConsultantProfileEditor } from '../components/Profile'

import { getParsedFormData } from '../helpers'
import { getAvatar, getConsultantProfile, updateConsultantProfile } from '../helpers/consultdex-wp-api'

import { useUser } from '../contexts'


export const ProfileEditor = () => {
  const [profile, setProfile] = useState()

  const { setUserAvatar } = useUser()


  useEffect(() => {

    getConsultantProfile()
      .then(results => {
        setProfile(results)
      })

  }, [])


  const handleUpdate = async values => {
    const updatedValues = await updateConsultantProfile(getParsedFormData(values))

    setProfile(updatedValues)

    setUserAvatar(await getAvatar())
  }


  return (
    <>
      {!!profile ? (
        <ConsultantProfileEditor
          profile={profile}
          onUpdate={handleUpdate}
        />
      ) : (
          <Loader fullWidth />
        )}
    </>
  )
}
