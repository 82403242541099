import React from 'react'
import PropTypes from 'prop-types'

import { objectListRenderer } from '../..'

import { Table } from '../../specialized-components'
import { icons, Card, IconButton, Loader } from '../../ui-blocks'


const tableColumns = [
  {
    field: 'title', title: 'Name'
  },
  {
    field: 'courses', title: 'Assigned Courses',
    renderer: objectListRenderer('title')
  }
]


export const StepsTable = ({ label, steps, courseSteps, onAddToCourse }) => {

  if (!steps) {
    return (<Loader fullWidth />)
  }

  if (!steps.length) {
    return null
  }


  const availableSteps = steps.filter(step =>
    !courseSteps.find(section => section.id === step.id)
  )

  const actionsRenderer = row => (
    <IconButton
      icon={icons.addCircle}
      variant="success"
      onClick={() => onAddToCourse(row)}
    />
  )


  return (
    <Card title={label} className="mb-5">
      {(availableSteps.length) ? (
        <Table
          columns={tableColumns}
          rows={availableSteps}
          actionsRenderer={onAddToCourse ? actionsRenderer : null}
        />
      ) : (
          <div className="text-center py-3">
            All the available {label} are assigned to this Course.
          </div>
        )}
    </Card>
  )
}


StepsTable.propTypes = {
  label: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object),
  courseSteps: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAddToCourse: PropTypes.func
}
