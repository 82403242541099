import React from 'react'
import { useHistory } from 'react-router-dom'

import { faFileSearch } from '@fortawesome/pro-duotone-svg-icons'

import { Icon, Button } from '../components/ui-blocks'


export const Page404 = () => {

  const history = useHistory()


  const redirectHome = () => {
    if (history) {
      history.replace(process.env.PUBLIC_URL)
    }
  }


  return (
    <div className="mt-5 mx-auto text-center">

      <Icon icon={faFileSearch} className="display-1 mb-5" />

      <h4 className="mb-5">
        We didn't find what you're looking for.
      </h4>

      <Button onClick={redirectHome} className="btn-lg px-4">
        Go to Dashboard
      </Button>

    </div >
  )

}
