import React from 'react'
import PropTypes from 'prop-types'

import { icons, Icon } from './Icon'


export const ExternalLinkButton = ({ variant = 'primary', disabled = false, className = '', children, ...props }) => {

  const disabledClassName = disabled ? 'disabled' : ''

  const disabledProps = disabled ? {
    tabIndex: '-1',
    'aria-disabled': true
  } : {}

  const icon = disabled ? icons.warningTriangle : icons.externalLink

  return (
    <a
      className={`btn btn-${variant} ${className} ${disabledClassName}`}
      target="_blank"
      rel="noopener noreferrer"
      role="button"
      {...disabledProps}
      {...props}
    >
      {children}
      <Icon icon={icon} className="ml-2" />
    </a>
  )

}


ExternalLinkButton.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'outline-primary']),
  disabled: PropTypes.bool,
  className: PropTypes.string
}
