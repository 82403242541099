import React from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'

import { useUser } from '../../contexts'
import { isCurrentlyOnLogin, redirectToLogin } from '../../helpers'

import sidebarMenuEntries from '../../config/sidebar-menu'
import avatarMenuEntries from '../../config/avatar-menu'

import { PrivateRoute } from './PrivateRoute'

import { createSidebarLayout } from './create-sidebar-layout'
import { getAvailableMenuEntries } from './helpers'


export const Router = ({ routes }) => {

  const { hasPermissions } = useUser()

  const availableSidebarMenuEntries = getAvailableMenuEntries(sidebarMenuEntries, routes, hasPermissions)
  const availableAvatarMenuEntries = getAvailableMenuEntries(avatarMenuEntries, routes, hasPermissions)


  if (!availableSidebarMenuEntries.length && !isCurrentlyOnLogin()) {
    redirectToLogin()

    return null
  }


  const SidebarLayout = createSidebarLayout(availableSidebarMenuEntries, availableAvatarMenuEntries)

  const routesWithLayout = routes.map(route => ({ layout: SidebarLayout, ...route }))

  const defaultPath = availableSidebarMenuEntries[0]?.route || '/login'


  const getRoute = (route, index) => {

    const RouteComponent = route.private ? PrivateRoute : Route

    const WrapperComponent = route.layout || React.Fragment

    if (!hasPermissions(route.permissions)) {
      return null
    }

    return (
      <RouteComponent path={route.path} key={index}>
        <WrapperComponent>
          <route.component />
        </WrapperComponent>
      </RouteComponent>
    )
  }


  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Switch>
        {routesWithLayout.map(getRoute)}
        <Route path="/">
          <Redirect to={defaultPath} />
        </Route>
      </Switch>
    </BrowserRouter>
  )

}
