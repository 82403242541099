import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useLocation, Link } from 'react-router-dom'

import { icons, IconButton } from '../ui-blocks'
import { useOutsideClick } from '..'


export const Sidebar = ({ sidebarMenuEntries, setIsSidebarVisible }) => {
  const path = useLocation().pathname
  const wrapperRef = useRef(null)

  useOutsideClick(wrapperRef, () => setIsSidebarVisible(false))


  const SidebarMenu = () =>
    sidebarMenuEntries.map(entry => {
      const className = (path === entry.route) ? 'sidebar-link-active' : ''

      return (
        <Link to={entry.route} key={entry.route} className={`sidebar-link ${className}`}>
          <span className="sidebar-link-label">
            {entry.label}
          </span>
        </Link>
      )
    })


  return (
    <div ref={wrapperRef}>
      <IconButton
        icon={icons.remove}
        onClick={() => setIsSidebarVisible(false)}
        className="ml-2 mt-1 lead d-lg-none"
      />
      <SidebarMenu />
    </div>
  )
}


Sidebar.propTypes = {
  sidebarMenuEntries: PropTypes.array.isRequired,
  setIsSidebarVisible: PropTypes.func.isRequired
}
