import React from 'react'

import { icons, FormField, Icon, IconButton, Input, Textarea } from '../../ui-blocks'

import { getTimeframeLabel } from './helpers'


export const ResumeExperience = ({
  item,
  dragHandleProps,
  handleDelete
}) => (
  <div className="pb-5 d-flex">
    <div>
      <div className="mr-1 px-1" {...dragHandleProps}>
        <Icon icon={icons.verticalDragHandle} />
      </div>
    </div>
    <div className="flex-grow-1">
      <div className="row">
        <div className="col-md-6">
          <FormField label="Title / Role" inputField={item.title}>
            <Input
              {...item.title.getStatusProps()}
              {...item.title.getProps()}
            />
          </FormField>
        </div>
        <div className="col-md-2">
          <FormField label="Start Year" inputField={item.start_date}>
            <Input
              type="number"
              {...item.start_date.getStatusProps()}
              {...item.start_date.getProps()}
            />
          </FormField>
        </div>
        <div className="col-md-2">
          <FormField label="End Year" inputField={item.end_date}>
            <Input
              placeholder="Present"
              type="number"
              {...item.end_date.getStatusProps()}
              {...item.end_date.getProps()}
            />
          </FormField>
        </div>
        <div className="col-md-2">
          <FormField label="Timeframe">
            <input
              className="form-control-plaintext font-weight-bold"
              readOnly
              type="text"
              value={getTimeframeLabel(item.start_date.value, item.end_date.value)}
            />
          </FormField>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <FormField label="Company Name" inputField={item.company_name}>
            <Input
              {...item.company_name.getStatusProps()}
              {...item.company_name.getProps()}
            />
          </FormField>
        </div>
        <div className="col-md-6">
          <FormField label="Company Location" inputField={item.location}>
            <Input
              {...item.location.getStatusProps()}
              {...item.location.getProps()}
            />
          </FormField>
        </div>
      </div>
      <FormField label="Description" inputField={item.description}>
        <Textarea
          rows="5"
          {...item.description.getStatusProps()}
          {...item.description.getProps()}
        />
      </FormField>
    </div>
    <div className="ml-1 px-1">
      <IconButton
        icon={icons.removeCircle}
        onClick={() => handleDelete(item.id)}
        className="faded danger-on-hover"
      />
    </div>
  </div >
)
