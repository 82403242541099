import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useModal } from '../../contexts'


export const useAsyncError = () => {
  // eslint-disable-next-line no-unused-vars
  const [_, setError] = useState()
  const { openModal } = useModal()
  const history = useHistory()

  return useCallback(
    e => {
      if (e instanceof Response) {
        if (e.status === 404) {
          history.replace('/404/')
          return
        }

        openModal({ body: e.statusText })
        return
      }

      setError(() => {
        throw e
      })
    },
    [history, openModal]
  )
}
