import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from './Icon'


export const IconButton = ({ icon, variant, className = '', ...props }) => {

  const textColorClass = variant ? `text-${variant}` : ''

  return (
    <button
      type="button"
      className={`icon-button ${textColorClass} ${className}`}
      {...props}
    >
      <Icon icon={icon} />
    </button>
  )
}


IconButton.propTypes = {
  icon: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['success', 'danger', 'dark']),
  className: PropTypes.string
}
