import React from 'react'
import { Prompt } from 'react-router-dom'


export const LeavingOnDirtyForm = ({ isDirty }) => (
  <Prompt
    when={isDirty}
    message="There are unsaved changes. Are you sure you want to leave?"
  />
)
