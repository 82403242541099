import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { icons, IconButton, Input } from '../../ui-blocks'

import { isYouTubeVideo } from './helpers'
import { getMediaElementFromVideoUrl, getMediaElementToRemove, mediaElementPropTypes } from './media-element'


export const VideoSelector = ({ media, setMedia, hasClearButton = false }) => {

  const [videoUrl, setVideoUrl] = useState((media?.payload?.sourceUrl) ? String(media.payload.sourceUrl) : '')


  const clearVideo = () => {
    setVideoUrl('')
    setMedia(getMediaElementToRemove())
  }


  const handleChange = event => {
    const newVideoUrl = event.target.value
    setVideoUrl(newVideoUrl)

    const newMedia = getMediaElementFromVideoUrl(String(newVideoUrl).trim())

    if (newMedia) {
      setMedia(newMedia)
    }
  }


  const videoProvider = isYouTubeVideo(videoUrl)
  const hasError = !!videoUrl && !videoProvider

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <label className="form-label my-1" htmlFor="input-youtube-video">
          YouTube Video URL
        </label>
        {videoUrl && hasClearButton && (
          <IconButton icon={icons.removeCircle} onClick={clearVideo} />
        )}
      </div>

      <Input
        id="input-youtube-video"
        isInvalid={hasError}
        onChange={handleChange}
        placeholder="https://youtube.com/..."
        type="url"
        value={videoUrl}
      />
    </div>
  )
}


VideoSelector.propTypes = {
  media: mediaElementPropTypes,
  setMedia: PropTypes.func.isRequired,
  hasClearButton: PropTypes.bool
}
