import React from 'react'

import { Helmet } from 'react-helmet'


export const HeaderScripts = () => {

  const hotjarSettings = process.env.REACT_APP_HOTJAR_SETTINGS

  if (!hotjarSettings) {
    return null
  }


  return (
    <Helmet script={[{
      type: 'text/javascript',
      innerHTML: `
      (function(h,o,t,j,a,r){
        h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
            h._hjSettings=${hotjarSettings};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      `
    }]} />
  )
}
