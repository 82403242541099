import React from 'react'

import { Card, ProgressBar } from '../ui-blocks'


export const CourseCard = ({ course }) => {

  const { status, progress } = course.learnerCourse

  const isCompleted = (status === 'completed')

  const { buttonLabel, actionUrl } = isCompleted ? {
    buttonLabel: null,
    actionUrl: course.link
  } : {
      buttonLabel: (status === 'not-started') ? 'Start' : 'Continue',
      actionUrl: (course.learnerCourse.nextStep) ? course.learnerCourse.nextStep.url : course.link
    }


  return (
    <Card imageProps={course.featuredMedia?.previewUrl ? {
      src: course.featuredMedia?.previewUrl,
      alt: 'Media Preview'
    } : {}}>

      <h5 className="mb-0">{course.title}</h5>
      <p className="text-right"><span className="text-muted">by</span> {course.author.title}</p>

      <div className="mb-1">
        <span className="text-muted">Team Size:</span> {course.teamSize}<br />
        <span className="text-muted">Skill Level:</span> {course.level}
      </div>

      <div className="mb-3">
        {course.specialties.map(specialty => (
          <span key={specialty.id} className="border border-light rounded-pill small px-2 mr-2 mb-1">
            {specialty.name}
          </span>
        ))}
      </div>

      {(!isCompleted && progress) && (
        <div className="mb-3">
          <ProgressBar current={progress.completed} maximum={progress.total} />
          <div className="small text-right">Completed Steps: {progress.completed} / Total: {progress.total}</div>
        </div>
      )}

      <div className="text-center">
        {isCompleted ? (
          <a
            className="bg-success text-white rounded py-2 px-3 d-inline-block stretched-link text-decoration-none"
            href={actionUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            You completed this Course
          </a>
        ) : (
            <a className="btn btn-primary stretched-link" href={actionUrl} target="_blank" rel="noopener noreferrer">
              { buttonLabel}
            </a>
          )}
      </div >

    </Card >
  )
}
