import React from 'react'
import PropTypes from 'prop-types'

import { icons, Button, Icon } from '../../ui-blocks'


export const ModalDialog = ({ body, buttons, contentRef, onClose = () => { } }) => {

  const getButton = (props, index) => {
    const { action, label, className = '', ...rawButtonProps } = props

    const buttonProps = {
      onClick: () => onClose(action),
      variant: 'outline-primary',
      ...rawButtonProps
    }

    return (
      <Button className={`my-0 ${className}`} {...buttonProps} key={index}>{props.label}</Button>
    )
  }


  return (
    <div className="modal-dialog">
      <div className="modal-content" ref={contentRef}>
        <div className="modal-header">
          <button
            aria-label="Close"
            className="close px-3"
            onClick={() => onClose(null)}
            type="button"
          >
            <Icon icon={icons.remove} aria-hidden="true" />
          </button>
        </div>
        {body && (
          <div className="modal-body my-3 px-2 text-center lead">
            {body}
          </div>
        )}
        {buttons && (
          <div className="modal-footer d-flex flex-row-reverse justify-content-center mb-5">
            {buttons.map(getButton)}
          </div>
        )}
      </div>
    </div>
  )
}


ModalDialog.propTypes = {
  body: PropTypes.node,
  buttons: PropTypes.arrayOf(PropTypes.object)
}
