import React from 'react'
import PropTypes from 'prop-types'


const defaultColumn = {
  align: null,
  renderer: (row, column) => row[column.field]
}

const getCellStyle = column =>
  column.align
    ? { textAlign: column.align }
    : {}


export const Table = ({ columns, rows, actionsRenderer }) => {

  const parsedColumns = columns.map(column => ({
    ...defaultColumn,
    ...column
  }))


  return (
    <div className="table-responsive">
      <table className="table bg-white mb-0">
        <thead>
          <tr>
            {parsedColumns.map(column => (
              <th key={column.field} scope="col" style={getCellStyle(column)}>{column.title}</th>
            ))}
            {actionsRenderer && (
              <th scope="col"></th>
            )}
          </tr>
        </thead>
        <tbody>
          {rows.map(row => (
            <tr key={row.id}>
              <>
                {parsedColumns.map(column => (
                  <td key={column.field} style={getCellStyle(column)}>
                    {column.renderer(row, column)}
                  </td>
                ))}
                {actionsRenderer && (
                  <td className="row-actions">
                    <span className="row-actions-wrapper">
                      {actionsRenderer(row)}
                    </span>
                  </td>
                )}
              </>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}


Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    align: PropTypes.oneOf(['left', 'center', 'right'])
  })).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  actionsRenderer: PropTypes.func
}
