import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { icons, Button, Icon } from '../ui-blocks'
import { Table } from '../specialized-components'

import { objectListRenderer, tableActionsRenderer } from '..'


const tableColumns = [
  {
    field: 'title', title: 'Name',
    renderer: row =>
      <Link to={`/lessons/${row.id}`} className="link-dark underline-on-hover py-2">
        <strong>{row.title}</strong>
      </Link>
  },
  {
    field: 'courses', title: 'Assigned Courses',
    renderer: objectListRenderer('title')
  }
]


export const Lessons = ({ lessons, deleteAction }) => (
  <>
    {!!lessons.length ? (
      <>

        <div className="d-flex justify-content-end mb-4 mr-3">
          <Link to="/lessons/0">
            <Button><Icon icon={icons.add} />Create Lesson</Button>
          </Link>
        </div>

        <div className="paper">
          <Table
            columns={tableColumns}
            rows={lessons}
            actionsRenderer={tableActionsRenderer({
              view: row => row.link,
              edit: row => `/lessons/${row.id}`,
              delete: deleteAction
            })}
          />
        </div>

      </>
    ) : (
        <div className="text-center mt-5">

          <Icon icon={icons.lessons} className="display-1 mb-4" />

          <h3 className="mb-4">
            Create your first Lesson
          </h3>

          <Link to="/lessons/0">
            <Button><Icon icon={icons.add} />Create Lesson</Button>
          </Link>

        </div>
      )
    }
  </>
)

Lessons.propTypes = {
  lessons: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteAction: PropTypes.func.isRequired
}
