import {
  Courses,
  EditCourse,
  EditLesson,
  EditTopic,
  LearnerCourses,
  Lessons,
  Login,
  Page404,
  ProfileEditor,
  Topics
} from '../sections'

import { ROLES, PERMISSIONS } from './roles-and-permissions'


const defaultRoute = {
  private: true,
  permissions: []
}


const routes = [
  {
    path: '/courses/:id',
    component: EditCourse,
    permissions: [ROLES.CONSULTANT, PERMISSIONS.COURSES]
  },
  {
    path: '/courses/',
    component: Courses,
    permissions: [ROLES.CONSULTANT, PERMISSIONS.COURSES]
  },
  {
    path: '/lessons/:id',
    component: EditLesson,
    permissions: [ROLES.CONSULTANT, PERMISSIONS.COURSES]
  },
  {
    path: '/lessons/',
    component: Lessons,
    permissions: [ROLES.CONSULTANT, PERMISSIONS.COURSES]
  },
  {
    path: '/topics/:id',
    component: EditTopic,
    permissions: [ROLES.CONSULTANT, PERMISSIONS.COURSES]
  },
  {
    path: '/topics/',
    component: Topics,
    permissions: [ROLES.CONSULTANT, PERMISSIONS.COURSES]
  },
  {
    path: '/profile/',
    component: ProfileEditor,
    permissions: [ROLES.CONSULTANT]
  },
  {
    path: '/courses/',
    component: LearnerCourses,
    permissions: [ROLES.LEARNER]
  },
  {
    path: '/login',
    component: Login,
    layout: null,
    private: false
  },
  {
    path: '/404',
    component: Page404
  }
]


export default routes.map(route => ({ ...defaultRoute, ...route }))
