import React from 'react'

import { getArrayWithoutEntry } from '../../../helpers'

import { SortableList } from '../../specialized-components'
import { icons, Icon, Button } from '../../ui-blocks'

import { ResumeExperience } from './ResumeExperience'


export const ResumeExperienceList = ({ experiences, setExperiences }) => {

  const handleAddExperience = () => {
    setExperiences([...experiences, {}])
  }


  const handleDelete = id => {
    const updatedExperiences = getArrayWithoutEntry(experiences, id)

    setExperiences(updatedExperiences)
  }


  return (
    <>
      <SortableList
        id="resumeExperience"
        items={experiences}
        setItems={setExperiences}
        itemRenderer={({ item, dragHandleProps }) =>
          ResumeExperience({ item, dragHandleProps, handleDelete })
        }
      />
      <div className="d-flex justify-content-end mt-2">
        <Button onClick={handleAddExperience} variant="secondary">
          <Icon icon={icons.add} />
          Add Experience
        </Button>
      </div>
    </>
  )
}
