import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { EditorState, AtomicBlockUtils, RichUtils, getDefaultKeyBinding } from 'draft-js'
import { stateFromHTML } from 'draft-js-import-html'
import { stateToHTML } from 'draft-js-export-html'

import Editor, { composeDecorators } from '@draft-js-plugins/editor'
import createFocusPlugin from '@draft-js-plugins/focus'
import createImagePlugin from '@draft-js-plugins/image'

import { useModal } from '../../../contexts'
import { icons, Icon } from '../../ui-blocks'

import { ImageSelector } from '../MediaSelector/ImageSelector'
import { getAlert } from '../Modal/modals'

import { BlockStyleControls } from './BlockStyleControls'
import { InlineStyleControls } from './InlineStyleControls'

import 'draft-js/dist/Draft.css'
import '@draft-js-plugins/focus/lib/plugin.css'


const focusPlugin = createFocusPlugin()

const decorator = composeDecorators(
  focusPlugin.decorator
)

const imagePlugin = createImagePlugin({ decorator })

const plugins = [
  focusPlugin,
  imagePlugin
]


export const HtmlEditor = ({ value, onChange, addImageToUpload, className = '' }) => {

  const [editorState, setEditorState] = useState(null)
  const [shouldIgnoreUpdate, setShouldIgnoreUpdate] = useState(false)
  const editor = useRef(null)
  const { openModal } = useModal()


  useEffect(() => {
    if (shouldIgnoreUpdate) {
      setShouldIgnoreUpdate(false)
      return
    }

    const state = value ? EditorState.createWithContent(stateFromHTML(value)) : EditorState.createEmpty()

    setEditorState(state)
  }, [value]) // eslint-disable-line react-hooks/exhaustive-deps


  const focusEditor = () => {
    editor.current.focus()
  }


  const handleChange = newEditorState => {
    setEditorState(newEditorState)

    if (typeof onChange === 'function') {
      setShouldIgnoreUpdate(true)
      onChange(stateToHTML(newEditorState.getCurrentContent()))
    }
  }


  const handleInsertImage = media => {
    if (!media) {
      return
    }

    const contentState = editorState.getCurrentContent()
    const contentStateWithEntity = contentState.createEntity(
      'image',
      'IMMUTABLE',
      { src: media.previewUrl }
    )

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()

    const newEditorState = EditorState.set(
      editorState,
      { currentContent: contentStateWithEntity },
      'create-entity'
    )

    setEditorState(
      AtomicBlockUtils.insertAtomicBlock(
        newEditorState,
        entityKey,
        ' '
      )
    )

    addImageToUpload(media)
  }


  const handleKeyCommand = (command, newEditorState) => {
    const newState = RichUtils.handleKeyCommand(newEditorState, command)

    if (newState) {
      handleChange(newState)
      return true
    }

    return false
  }


  const mapKeyToEditorCommand = e => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(
        e,
        editorState,
        4 /* maxDepth */
      )

      if (newEditorState !== editorState) {
        handleChange(newEditorState)
      }

      return
    }

    return getDefaultKeyBinding(e)
  }


  const toggleBlockType = blockType => {
    handleChange(
      RichUtils.toggleBlockType(
        editorState,
        blockType
      )
    )

    focusEditor()
  }


  const toggleInlineStyle = inlineStyle => {
    handleChange(
      RichUtils.toggleInlineStyle(
        editorState,
        inlineStyle
      )
    )

    focusEditor()
  }


  const notifyInvalidFile = message => {
    openModal(
      getAlert(message)
    )
  }


  return editorState && (
    <div className={className}>
      <div className="d-md-flex mb-2">
        <BlockStyleControls
          editorState={editorState}
          onToggle={toggleBlockType}
        />

        <div className="d-flex">
          <div className="border-left mt-1 mt-md-0 ml-md-1 pl-2">
            <InlineStyleControls
              editorState={editorState}
              onToggle={toggleInlineStyle}
            />
          </div>

          {addImageToUpload && (
            <div className="border-left mt-1 mt-md-0 ml-md-1 pl-2">
              <ImageSelector
                setMedia={handleInsertImage}
                trigger={(
                  <button className="btn btn-outline-dark border-0" style={{
                    fontSize: '1.25rem',
                    padding: '.15rem .5rem'
                  }}>
                    <Icon icon={icons.image} className="mr-0" />
                  </button>
                )}
                notifyInvalidFile={notifyInvalidFile}
              />
            </div>
          )}
        </div>
      </div>

      <div className="border bg-white" >
        <Editor
          blockStyleFn={getBlockStyle}
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={mapKeyToEditorCommand}
          onChange={handleChange}
          plugins={plugins}
          ref={editor}
          spellCheck={true}
        />
      </div>
    </div>
  )

}


function getBlockStyle(block) {
  switch (block.getType()) {
    case 'unstyled':
      return 'paragraph'
    default:
      return null
  }
}


HtmlEditor.propTypes = {
  addImageToUpload: PropTypes.func,
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string
}
