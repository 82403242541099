import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'


export const InlineEdit = ({ value = '', onChange, placeholder, autoFocus, ...props }) => {
  const [isEditing, setEditing] = useState(false)
  const [internalValue, setInternalValue] = useState('')

  const inputRef = useRef()


  useLayoutEffect(() => {
    if (autoFocus) {
      setEditing(true)
    }
  }, [autoFocus])

  useEffect(() => {
    if (inputRef?.current && isEditing === true) {
      inputRef.current.focus()
    }
  }, [isEditing, inputRef])

  useEffect(() => {
    setInternalValue(value)
  }, [value])


  const confirmEdit = () => {
    setEditing(false)
    onChange(internalValue)
  }

  const handleKeyDown = event => {
    const { key } = event
    const confirmKeys = ['Enter', 'Tab']
    const cancelKeys = ['Escape']

    if (confirmKeys.includes(key)) {
      confirmEdit()
      return
    }

    if (cancelKeys.includes(key)) {
      setEditing(false)
      setInternalValue(value)
      return
    }
  }


  return (
    <span {...props}>
      {isEditing ? (
        <input
          className="inline-edit-input"
          onBlur={confirmEdit}
          onChange={e => setInternalValue(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          ref={inputRef}
          type="text"
          value={internalValue}
        />
      ) : (
          <span
            className={`inline-edit-text ${value ? '' : 'inline-edit-placeholder'}`}
            onClick={() => setEditing(true)}
          >
            {value || (<em>{placeholder}</em>)}
          </span>
        )}
    </span>
  )
}


InlineEdit.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool
}
