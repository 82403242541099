import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { getFuzzy, getSortedByKey, includesFuzzy } from '../../helpers'
import { icons, Alert, Icon } from '../ui-blocks'

import { useOutsideClick } from '..'


const maximumSpecialties = 3


const getFilteredSpecialties = (entries, filter) =>
  entries.filter(entry => includesFuzzy(entry.name, filter))


const isSpecialtyAlreadySelected = (currentSpecialties, specialty) =>
  !!currentSpecialties.find(current => current.id === specialty.id)


export const SpecialtiesSelector = ({ specialties, setSpecialties, availableSpecialties = [], className = '' }) => {
  const [expanded, setExpanded] = useState(false)
  const [filter, setFilter] = useState('')

  const wrapperRef = useRef(null)
  const inputRef = useRef(null)
  useOutsideClick(wrapperRef, () => setExpanded(false))

  const currentSpecialties = getSortedByKey(specialties, 'name')


  const handleKeyPress = e => {
    if (e.keyCode === 27) {
      inputRef.current.blur()
      setExpanded(false)
    }
  }

  const openDropdown = () => {
    inputRef.current.focus()
    setExpanded(true)
  }

  const toggleDropdown = () => {
    if (!expanded) {
      inputRef.current.focus()
    }

    setExpanded(!expanded)
  }

  const handleFilterChange = e => {
    setFilter(e.target.value)
  }


  const removeSpecialty = specialty => {
    const newSpecialties = currentSpecialties.filter(current => current.id !== specialty.id)

    setSpecialties(getSortedByKey(newSpecialties, 'name'))
  }


  const toggleSpecialty = newSpecialty => {
    if (isSpecialtyAlreadySelected(currentSpecialties, newSpecialty)) {
      removeSpecialty(newSpecialty)
      return
    }

    if (currentSpecialties.length >= maximumSpecialties) {
      return
    }


    setSpecialties(
      getSortedByKey([...currentSpecialties, newSpecialty], 'name')
    )
  }


  const filteredSpecialties = getFilteredSpecialties(availableSpecialties, getFuzzy(filter))


  return (
    <>
      {currentSpecialties.map(specialty => (
        <button key={specialty.id}
          className={'btn btn-outline-danger btn-sm mr-1 mb-1 px-2'}
          onClick={() => removeSpecialty(specialty)}
        >
          <Icon icon={icons.remove} />
          {specialty.name}
        </button>))}

      <div className={`dropdown ${className}`} ref={wrapperRef} onKeyDown={handleKeyPress}>
        <div className="input-group mb-3">
          <input
            className="form-control input-with-primary-button"
            onChange={handleFilterChange}
            onFocus={openDropdown}
            placeholder="Type to search"
            ref={inputRef}
            type="text"
            value={filter}
          />
          <button
            className="btn btn-primary shadow-none px-3 dropdown-toggle"
            type="button"
            onClick={toggleDropdown}
            aria-label="Search"
          >
            <Icon icon={icons.search} />
          </button>
        </div>

        <div className={`dropdown-menu px-2 shadow-sm w-100 ${expanded ? 'show' : ''}`}>

          {(currentSpecialties.length >= maximumSpecialties) && (
            <Alert variant="warning" className="py-1">
              Maximum Specialties is {maximumSpecialties}
            </Alert>
          )}

          {!!filteredSpecialties.length ? filteredSpecialties.map(specialty => {
            const classVariant = isSpecialtyAlreadySelected(currentSpecialties, specialty)
              ? 'btn-info btn-danger-on-hover'
              : 'btn-light'

            return (
              <button
                key={specialty.id}
                className={`btn ${classVariant} mr-1 mb-1`}
                onClick={() => toggleSpecialty(specialty)}
              >
                {specialty.name}
              </button>
            )
          }) : (
            <span>There are no specialties for your search.</span>
          )}

        </div>
      </div>
    </>
  )
}


SpecialtiesSelector.propTypes = {
  specialties: PropTypes.array.isRequired,
  setSpecialties: PropTypes.func.isRequired,
  availableSpecialties: PropTypes.array.isRequired
}
