import React from 'react'
import PropTypes from 'prop-types'


export const ProgressBar = ({ current, maximum, className = '' }) =>
  (maximum !== 0) ? (
    <div className={`progress ${className}`}>
      <div
        className="progress-bar"
        role="progressbar"
        style={{ width: `${current / maximum * 100}%` }}
        aria-valuenow={current}
        aria-valuemin="0"
        aria-valuemax={maximum}
      >
      </div>
    </div>
  ) : null


ProgressBar.propTypes = {
  current: PropTypes.number.isRequired,
  maximum: PropTypes.number.isRequired,
  className: PropTypes.string
}
