import React from 'react'
import { icons, Icon } from '../../ui-blocks'


export const getAlert = body => ({
  body,
  buttons: [
    {
      label: 'Ok',
      action: true,
      variant: 'primary'
    }
  ]
})


export const getConfirmModal = ({ body, confirmProps }) => ({
  body,
  buttons: [
    {
      ...confirmProps,
      action: true
    },
    {
      label: 'Cancel',
      action: false
    }
  ]
})


export const getConfirmDelete = body =>
  getConfirmModal({
    body,
    confirmProps: {
      label: (<><Icon icon={icons.delete} />Delete</>),
      variant: 'danger'
    }
  })
