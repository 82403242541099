import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '../Button'
import { icons, Icon } from '../Icon'
import { Loader } from '../Loader'


const loaderSize = {
  height: '1rem',
  width: '1rem'
}


const IconPlaceholder = (<span className="d-inline-block" style={loaderSize}></span>)

const WarningIcon = (<Icon icon={icons.warningTriangle} className="mr-0" style={loaderSize} />)


export const SubmitButton = ({
  isLoading = false,
  isValid = true,
  fullWidth = false,
  className = '',
  children,
  ...props
}) => {

  let mainIcon = IconPlaceholder

  if (!isValid) {
    mainIcon = WarningIcon
  }

  if (isLoading) {
    mainIcon = (<Loader style={loaderSize} />)
  }


  return (
    <Button
      disabled={isLoading || !isValid}
      className={`px-3 ${className}${fullWidth ? ' w-100' : ''}`}
      {...props}>
      <>
        {mainIcon}
        <span className="mx-2">{children}</span>
        {IconPlaceholder}
      </>
    </Button>
  )
}

SubmitButton.propTypes = {
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  isLoading: PropTypes.bool,
  isValid: PropTypes.bool
}
