import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { icons, Button, Icon } from '../ui-blocks'
import { Table } from '../specialized-components'

import { objectListRenderer, statusRenderer, tableActionsRenderer } from '..'


const tableColumns = [
  {
    field: 'title', title: 'Course Name',
    renderer: row =>
      <Link to={`/courses/${row.id}`} className="link-dark underline-on-hover py-2">
        <strong>{row.title}</strong>
      </Link>
  },
  {
    field: 'status', title: 'Status',
    renderer: statusRenderer
  },
  {
    field: 'enrolledUsersCount', title: 'Current Learners',
    align: 'right'
  },
  {
    field: 'allTimeEnrolledUsersCount', title: 'Learners All Time',
    align: 'right'
  },
  {
    field: 'specialties', title: 'Specialties',
    renderer: objectListRenderer('name')
  }
]


export const Courses = ({ courses, deleteAction }) => (
  <>
    {!!courses.length ? (
      <>

        <div className="d-flex justify-content-end mb-4 mr-3">
          <Link to="/courses/0">
            <Button><Icon icon={icons.add} />Create Course</Button>
          </Link>
        </div>

        <div className="paper">
          <Table
            columns={tableColumns}
            rows={courses}
            actionsRenderer={tableActionsRenderer({
              view: row => row.link,
              edit: row => `/courses/${row.id}`,
              delete: deleteAction
            })}
          />
        </div>

      </>
    ) : (
        <div className="text-center mt-5">

          <Icon icon={icons.courses} className="display-1 mb-4" />

          <h3 className="mb-4">
            Create your first Course
          </h3>

          <Link to="/courses/0">
            <Button><Icon icon={icons.add} />Create Course</Button>
          </Link>

        </div>
      )
    }
  </>
)

Courses.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteAction: PropTypes.func.isRequired
}
