import React from 'react'

import { ErrorHandler } from './components/error-handling'
import { Router } from './components/Navigation'

import { ModalProvider, UserProvider } from './contexts'
import { HeaderScripts } from './HeaderScripts'

import routes from './config/routes'


export default () => (
  <>
    <HeaderScripts />
    <ModalProvider>
      <ErrorHandler>
        <UserProvider>
          <Router routes={routes} />
        </UserProvider>
      </ErrorHandler>
    </ModalProvider>
  </>
)
