export default [
  {
    label: 'Your Courses',
    route: '/courses/'
  },
  {
    label: 'Profile',
    route: '/profile/'
  }
]
