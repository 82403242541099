import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { useForm, getPurgedEmbedImages, isFormWithHtmlDirty } from '../../helpers'

import { HtmlEditor, LeavingOnDirtyForm, VideoSelector } from '../specialized-components'
import { icons, Button, Card, ExternalLinkButton, Icon, Input, SubmitButton } from '../ui-blocks'

import topicFields from './topic-fields'


const parseValues = topic => ({
  title: topic.title,
  content: topic.content,
  featuredMedia: topic.featuredMedia,
  embedImages: []
})


export const EditTopic = ({ topic, onUpdate, onDelete }) => {
  const [form, setFormValues, validateForm] = useForm(topicFields)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setFormValues(parseValues(topic))
  }, [topic]) // eslint-disable-line react-hooks/exhaustive-deps


  const handleUpdate = async () => {
    const isFormValid = validateForm()

    if (!isFormValid) {
      return
    }

    setIsLoading(true)

    await onUpdate({
      ...form.values,
      embedImages: getPurgedEmbedImages(form.values.content, form.values.embedImages)
    })

    setIsLoading(false)
  }

  const handleAddImageToUpload = media => {
    form.fields.embedImages.setValue([...form.fields.embedImages.value, media])
  }


  if (!Object.keys(form.values).length) {
    return null
  }


  const isNew = !topic.id
  const isFormDirty = isFormWithHtmlDirty(form)

  const media = form.fields.featuredMedia.value


  return (
    <>
      <LeavingOnDirtyForm isDirty={isFormDirty} />

      <div className="d-md-flex justify-content-between align-items-center mb-4">
        <h1 className="mb-3 mb-md-0">{isNew ? 'New Topic' : 'Edit Topic'}</h1>

        <div className="d-flex justify-content-end align-items-center">
          {!isNew && (
            <ExternalLinkButton
              href={topic.link}
              className="mr-3"
              variant="secondary"
              disabled={isFormDirty}
            >
              Preview
            </ExternalLinkButton>
          )}
          <SubmitButton
            onClick={handleUpdate}
            isLoading={isLoading}
            isValid={form.isValid}
          >
            {isNew ? 'Create' : 'Update'}
          </SubmitButton>
        </div>
      </div>

      <Input
        className="mb-3 border-0 form-control-lg paper"
        placeholder="Insert title..."
        {...form.fields.title?.getStatusProps()}
        {...form.fields.title?.getProps()}
      />

      {!isNew && (
        <>
          {(!!topic.courses.length) ? (
            <>
              Assigned to the Courses:
              {topic.courses.map((course, i) => (
                <span key={course.id}>
                  {(i > 0) && ','}
                  <Link to={`/courses/${course.id}`} className="link-dark underline-on-hover ml-1">
                    <strong>{course.title}</strong>
                  </Link>
                </span>
              ))}
            </>
          ) : (
            <span>This Topic is not assigned to any Course.</span>
          )}
        </>
      )}

      <div className="row my-5">
        <div className="col-xl-8 mb-5 mb-xl-0">

          <Card title="Topic Content" className="html-editor-large-content">
            <HtmlEditor
              {...form.fields.content?.getProps()}
              addImageToUpload={handleAddImageToUpload}
            />
          </Card>

        </div>
        <div className="col-xl-4">

          <Card title="Content Video" imageProps={media?.previewUrl ? {
            src: media.previewUrl,
            alt: 'Media Preview'
          } : {}}>
            <VideoSelector media={media} setMedia={form.fields.featuredMedia.setValue} hasClearButton />
          </Card>

        </div>
      </div>

      {!isNew && (
        <Button
          onClick={onDelete}
          variant="outline-danger" className="btn-danger-on-hover px-3"
        >
          <Icon icon={icons.delete} className="mr-2" />
          Delete this Topic
        </Button>
      )}
    </>
  )
}


EditTopic.propTypes = {
  topic: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}
