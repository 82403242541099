import React, { useLayoutEffect, useState } from 'react'
import { useHistory, useParams, Link, Redirect } from 'react-router-dom'

import { EditCourse as EditCourseForm } from '../components/Courses'

import { useAsyncError } from '../components/error-handling'
import { getConfirmDelete } from '../components/specialized-components'
import { Loader } from '../components/ui-blocks'

import { useModal } from '../contexts'

import { getParsedFormData, getSortedByKey } from '../helpers/'
import {
  getAvailableSpecialties,
  getCourse,
  getLessons,
  getTopics,
  createCourse,
  updateCourse,
  deleteCourse
} from '../helpers/consultdex-wp-api'


export const EditCourse = () => {
  const id = Number(useParams().id)

  const [course, setCourse] = useState(null)
  const [lessons, setLessons] = useState(null)
  const [topics, setTopics] = useState(null)
  const [availableSpecialties, setAvailableSpecialties] = useState([])
  const { openModal } = useModal()
  const history = useHistory()

  const handleAsyncError = useAsyncError()


  useLayoutEffect(() => {
    getAvailableSpecialties()
      .then(specialties => {
        setAvailableSpecialties(getSortedByKey(specialties, 'name'))
      })

    if (!id) {
      setCourse({})
      return
    }

    getCourse(id)
      .then(results => {
        setCourse(results)
      }, handleAsyncError)

    getLessons()
      .then(results => {
        setLessons(results)
      })

    getTopics()
      .then(results => {
        setTopics(results)
      })
  }, [id, handleAsyncError])


  const handleUpdate = async values => {
    if (course?.id) {
      setCourse(await updateCourse(course?.id, getParsedFormData(values)))
      return
    }

    const createdCourse = await createCourse(getParsedFormData(values))
    setCourse(createdCourse)
  }


  if (course?.id && (id !== course.id)) {
    return (<Redirect to={`/courses/${course.id}`} />)
  }


  const handleDelete = async () => {
    if (await openModal(
      getConfirmDelete(<>You will delete the course <strong>{course.title}</strong>.</>)
    )) {
      await deleteCourse(course.id)

      history.push('/courses/')
    }
  }


  return (
    <>
      <Link to="/courses/" className="text-dark mb-3 d-inline-block">Back to Courses</Link>
      {course ?
        <EditCourseForm
          course={course}
          lessons={lessons}
          topics={topics}
          availableSpecialties={availableSpecialties}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
        />
        : <Loader fullWidth />
      }
    </>
  )

}
