import React, { useEffect, useState } from 'react'

import { ModalBackDrop } from '../specialized-components'

import { Sidebar } from './Sidebar'
import { TopBar } from './TopBar'

import { useViewport } from '../use-viewport'


export const createSidebarLayout = (sidebarMenuEntries, avatarMenuEntries) =>
  ({ children }) => {

    const [isSidebarVisible, setIsSidebarVisible] = useState(false)
    const { width } = useViewport()

    const sidebarClassName = isSidebarVisible ? 'sidebar-visible' : ''

    useEffect(() => {
      const lgBreakpoint = parseInt(
        getComputedStyle(document.documentElement)
          .getPropertyValue('--bs-breakpoint-lg'),
        10)

      if (width >= lgBreakpoint) {
        setIsSidebarVisible(false)
      }
    }, [width])


    return (
      <div className="dashboard-wrapper">

        <aside className={`dashboard-sidebar pt-lg-5 ${sidebarClassName}`}>
          <Sidebar
            sidebarMenuEntries={sidebarMenuEntries}
            setIsSidebarVisible={setIsSidebarVisible}
          />
        </aside>

        <main className="pb-5 px-2 px-lg-5 bg-light overflow-hidden position-relative">
          <div className="dashboard-sidebar-main-content mx-auto">
            <TopBar
              avatarMenuEntries={avatarMenuEntries}
              setIsSidebarVisible={setIsSidebarVisible}
            />
            <div className="py-5">
              {children}
            </div>
          </div>
        </main>

        {isSidebarVisible && <ModalBackDrop />}
      </div>
    )
  }
