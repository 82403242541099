import React, { useState, createContext, useContext } from 'react'

import { decodeJwt, getUserTokenFromCookie, setUserTokenCookie } from '../helpers/auth'
import { ROLES } from '../config/roles-and-permissions'

import { createHasPermissions, getUserRole } from './user-helpers'


const UserContext = createContext()
UserContext.displayName = 'UserContext'


export const useUser = () => {
  const userContext = useContext(UserContext)

  return {
    ...userContext
  }
}


export const UserProvider = ({ children }) => {
  const [userToken, setUserToken] = useState(decodeJwt(getUserTokenFromCookie()))
  const [userAvatar, setUserAvatar] = useState()


  const logoutUser = () => {
    setUserTokenCookie('')

    setUserToken(null)
    setUserAvatar(null)
  }


  if (userToken && (!userToken.exp || userToken.exp < Date.now() / 1000)) {
    logoutUser()
  }

  const setUserTokenProxy = token => {
    setUserTokenCookie(token)

    setUserToken(decodeJwt(token))
  }


  const role = getUserRole(userToken?.permissions || [], ROLES)
  const hasPermissions = createHasPermissions(userToken?.permissions || [])


  return (
    <UserContext.Provider value={{
      userToken,
      setUserToken: setUserTokenProxy,
      userAvatar,
      setUserAvatar,
      role,
      hasPermissions,
      logoutUser
    }}>
      {children}
    </UserContext.Provider>
  )
}
