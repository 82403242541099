import {
  faListOl,
  faListUl
} from '@fortawesome/pro-regular-svg-icons'

import {
  faH1,
  faH2,
  faH3,
  faH4,
} from '@fortawesome/pro-solid-svg-icons'

import {
  faBold,
  faItalic,
  faQuoteLeft
} from '@fortawesome/free-solid-svg-icons'


export const blockTypes = [
  { label: 'H1', style: 'header-one', icon: faH1 },
  { label: 'H2', style: 'header-two', icon: faH2 },
  { label: 'H3', style: 'header-three', icon: faH3 },
  { label: 'H4', style: 'header-four', icon: faH4 },
  { label: 'Blockquote', style: 'blockquote', icon: faQuoteLeft },
  { label: 'UL', style: 'unordered-list-item', icon: faListUl },
  { label: 'OL', style: 'ordered-list-item', icon: faListOl }
]


export const inlineStyles = [
  { label: 'Bold', style: 'BOLD', icon: faBold },
  { label: 'Italic', style: 'ITALIC', icon: faItalic }
]
