import React from 'react'


const getStyles = (width, height) =>
  ({
    root: {
      width,
      height,
      overflow: 'hidden'
    },
    image: {
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height,
      width,
    }
  })


export const ImagePreview = ({ src, width, height, ...props }) => {
  const styles = getStyles(width, height)

  return (
    <div style={styles.root}>
      {src && (
        <div
          style={{
            ...styles.image,
            backgroundImage: `url(${src})`
          }}
          {...props}
        ></div>
      )}
    </div>
  )
}
