import PropTypes from 'prop-types'

import { getVideoProvider, getYouTubeThumbnailUrl, getYouTubeVideoId } from './helpers'


export const mediaElementPropTypes = PropTypes.shape({
  type: PropTypes.oneOf(['UploadImage', 'Image', 'Remove', 'YouTube']).isRequired,
  previewUrl: PropTypes.string.isRequired,
  payload: PropTypes.object
})


export const getMediaElementFromBrowserFile = file => ({
  type: 'UploadImage',
  previewUrl: URL.createObjectURL(file),
  payload: file
})


export const getMediaElementFromImageUrl = imageUrl => ({
  type: 'Image',
  previewUrl: imageUrl,
  payload: null
})


export const getMediaElementFromVideoUrl = videoUrl => {
  const videoProvider = getVideoProvider(videoUrl)

  if (!videoProvider) {
    return null
  }

  const youTubeId = getYouTubeVideoId(videoUrl)

  if (!youTubeId) {
    throw new Error('Only YouTube Videos are supported.')
  }

  const previewUrl = getYouTubeThumbnailUrl(youTubeId)


  return {
    type: videoProvider,
    previewUrl,
    payload: {
      id: youTubeId,
      sourceUrl: videoUrl
    }
  }
}


export const getMediaElementToRemove = () => ({
  type: 'Remove',
  previewUrl: '',
  payload: null
})
