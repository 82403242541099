import React, { useState } from 'react'

import { Alert } from '../components/ui-blocks'

import { LoginForm } from '../components'
import { useUser } from '../contexts/user'
import { useForm, followRedirectUrl, getFrontEndUrlFromRedirection, login } from '../helpers'

import { loginFields } from '../components/Auth/auth-forms'

import background from '../images/img-login-bg.png'


export const Login = () => {
  const { userToken, setUserToken } = useUser()

  const [form] = useForm(loginFields)

  const [error, setError] = useState(false)
  const [isLoading, setLoading] = useState(false)


  const submit = async () => {
    setLoading(true)

    try {
      const response = await login(form.values)

      setUserToken(response.data.jwt)
    } catch (err) {
      setError(true)
      setLoading(false)
    }
  }


  if (userToken) {
    followRedirectUrl()

    return null
  }


  return (
    <div className="login-page-bg vh-100 overflow-auto pt-md-5">
      <div className="content-wrap container mx-auto mt-3 mt-lg-5 p-3 p-sm-0">
        <div className="row bg-white w-100 mx-auto">
          <div className="d-none d-lg-block col-7 p-0 position-relative">
            <div className="text-wrap position-absolute px-5 mx-auto">
              <h1 className="mb-3 px-5">Let's show off</h1>
              <p className="px-5">
                Consultdex empowers consultants just like you to show your best self.
                Document your best work and make money while you sleep.
              </p>
            </div>
            <img className="w-100" src={background} alt="" />
          </div>
          <div className="col-lg-5 p-3 p-lg-5 d-lg-flex align-items-center">
            <div className="sign-in-form p-3 p-xl-5 w-100">
              <div className="mb-5">
                <h4 className="mb-3">
                  Sign In
                </h4>
                {error && (
                  <Alert variant="danger" className="mb-4">
                    Invalid credentials
                  </Alert>
                )}

                {!!Object.keys(form.fields).length && (
                  <LoginForm
                    fields={form.fields}
                    isFormValid={form.isValid}
                    isLoading={isLoading}
                    onSubmit={submit}
                  />
                )}
              </div>

              <p className="text-right">
                Return to <a href={getFrontEndUrlFromRedirection()}>Consultdex</a>
              </p>
            </div>
          </div>
        </div>

      </div>

    </div >
  )
}
