import { isRequired, isValidMail } from '../../helpers/Form'


export const loginFields = {
  email: {
    validators: [isRequired, isValidMail]
  },
  password: {
    validators: [isRequired]
  }
}
