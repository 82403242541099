import React from 'react'
import PropTypes from 'prop-types'

import { getArrayWithoutEntry } from '../../../helpers'

import { SortableList } from '../../specialized-components'

import { icons, Icon } from '../../ui-blocks'
import { networks as availableNetworks, SocialNetworkLinkInput } from './SocialNetworkLinkInput'


export const SocialNetworkLists = ({ networks, setNetworks }) => {

  const handleAddNetwork = networkId => {
    setNetworks([...networks, {
      network: networkId
    }])
  }


  const handleDelete = id => {
    const updatedNetworks = getArrayWithoutEntry(networks, id)

    setNetworks(updatedNetworks)
  }

  const usedNetworkIds = networks.map(networkEntry => networkEntry.network.value)

  const unusedNetworks = availableNetworks.filter(networkEntry => !usedNetworkIds.includes(networkEntry.id))


  return (
    <>
      <SortableList
        id="socialNetworks"
        items={networks}
        setItems={setNetworks}
        itemRenderer={({ item, dragHandleProps }) =>
          SocialNetworkLinkInput({ item, dragHandleProps, handleDelete })
        }
      />
      <div className="d-flex justify-content-end align-items-center mt-2">
        <span className="mr-2">
          <Icon icon={icons.add} className="mr-1" />Add Network
        </span>
        {unusedNetworks.map(network => (
          <button
            key={network.id}
            type="button"
            onClick={() => handleAddNetwork(network.id)}
            className="icon-button bg-white text-dark ml-1 rounded-circle"
            style={{ height: '1.8rem', width: '1.8rem' }}
          >
            <Icon icon={network.icon} style={{ width: '1rem' }} />
          </button>
        ))}
      </div>
    </>
  )
}


SocialNetworkLists.propTypes = {
  networks: PropTypes.array.isRequired,
  setNetworks: PropTypes.func.isRequired
}
