import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useForm, isFormWithHtmlDirty } from '../../helpers'

import { HtmlEditor, LeavingOnDirtyForm } from '../specialized-components'
import { icons, Button, Card, ExternalLinkButton, Icon, Input, SubmitButton } from '../ui-blocks'

import lessonFields from './lesson-fields'


const parseValues = lesson => ({
  title: lesson.title,
  content: lesson.content
})


export const EditLesson = ({ lesson, onUpdate, onDelete }) => {
  const [form, setFormValues, validateForm] = useForm(lessonFields)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setFormValues(parseValues(lesson))
  }, [lesson]) // eslint-disable-line react-hooks/exhaustive-deps


  const handleUpdate = async () => {
    const isFormValid = validateForm()

    if (!isFormValid) {
      return
    }

    setIsLoading(true)

    await onUpdate(form.values)

    setIsLoading(false)
  }


  if (!Object.keys(form.values).length) {
    return null
  }


  const isNew = !lesson.id

  const isFormDirty = isFormWithHtmlDirty(form)


  return (
    <>
      <LeavingOnDirtyForm isDirty={isFormDirty} />

      <div className="d-md-flex justify-content-between align-items-center mb-4">
        <h1 className="mb-3 mb-md-0">{isNew ? 'New Lesson' : 'Edit Lesson'}</h1>

        <div className="d-flex justify-content-end align-items-center">
          {!isNew && (
            <ExternalLinkButton
              href={lesson.link}
              className="mr-3"
              variant="secondary"
              disabled={isFormDirty}
            >
              Preview
            </ExternalLinkButton>
          )}
          <SubmitButton
            onClick={handleUpdate}
            isLoading={isLoading}
            isValid={form.isValid}
          >
            {isNew ? 'Create' : 'Update'}
          </SubmitButton>
        </div>
      </div>

      <Input
        className="mb-5 border-0 form-control-lg paper"
        placeholder="Insert title..."
        {...form.fields.title?.getStatusProps()}
        {...form.fields.title?.getProps()}
      />

      <div className="row mb-5">
        <div className="col-lg-8 mb-5 mb-lg-0">

          <Card title="Lesson Introduction">
            <HtmlEditor {...form.fields.content?.getProps()} />
          </Card>

        </div>
      </div>

      {!isNew && (
        <Button
          onClick={onDelete}
          variant="outline-danger" className="btn-danger-on-hover px-3"
        >
          <Icon icon={icons.delete} className="mr-2" />
          Delete this Lesson
        </Button>
      )}

    </>
  )
}


EditLesson.propTypes = {
  lesson: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}
