import PropTypes from 'prop-types'
import React from 'react'


export const Card = ({ title = '', imageProps, className = '', children, ...props }) => (
  <div className={className} {...props}>
    {title && (
      <h5 className="card-title">{title}</h5>
    )}
    <div className="card" {...props}>
      {imageProps &&
        <ImageCard
          hasBody={!!children}
          {...imageProps}
        />
      }
      {children && (
        <div className="card-body">
          {children}
        </div>
      )}
    </div>
  </div>
)


function ImageCard({ hasBody = false, alt = '', className = '', ...props }) {
  const imageClass = hasBody ? 'card-img-top' : 'card-img'

  return (
    <img
      className={`${imageClass} ${className}`}
      alt={alt}
      {...props}
    />
  )
}


Card.propTypes = {
  title: PropTypes.string,
  imageProps: PropTypes.object,
  className: PropTypes.string
}
