import React from 'react'
import PropTypes from 'prop-types'

import { icons, Icon } from '../ui-blocks'


const types = {
  'lesson': {
    label: 'LESSON',
    depth: 0
  },
  'topic': {
    label: 'TOPIC',
    depth: 1
  }
}


export const CoursePart = ({
  type,
  title,
  actions,
  dragHandleProps,
  isNew = false,
  prefix,
  className = '',
  ...props
}) => {

  if (!types[type]) {
    return null
  }


  const { label: typeLabel, depth } = types[type]

  const depthStyle = {
    marginLeft: `${depth * 1.5}rem`
  }

  const leftPadding = dragHandleProps ? 2 : 4

  return (
    <div className={`paper py-2 pl-${leftPadding} pr-4 d-flex align-items-center ${className}`}
      {...props}
      style={depthStyle}
    >
      {dragHandleProps && (
        <div className="mr-1 px-3" {...dragHandleProps}>
          <Icon icon={icons.verticalDragHandle} />
        </div>
      )}
      <div className="flex-grow-1 d-flex">
        {prefix && (<strong className="bg-info text-white rounded-sm px-1 mr-2">{prefix}</strong>)}
        {isNew && (<span className="text-info mr-1">NEW</span>)}
        {type && (<strong className="mr-1">{typeLabel}:</strong>)}
        <span className="flex-grow-1">
          {title}
        </span>
      </div>
      {actions &&
        <div className="ml-auto pl-3">
          {actions}
        </div>}
    </div>
  )
}


CoursePart.propTypes = {
  type: PropTypes.oneOf(Object.keys(types)),
  title: PropTypes.node,
  actions: PropTypes.node,
  dragHandleProps: PropTypes.object,
  isNew: PropTypes.bool,
  prefix: PropTypes.string,
  className: PropTypes.string
}
