import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '../../ui-blocks'
import { mediaElementPropTypes, ImageSelector } from '../../specialized-components'

import { ImagePreview } from './ImagePreview'


export const ProfilePhoto = ({ media, setMedia, diameter }) => {

  const imageSelectorLabel = media ? 'Replace Image' : 'Select Image'


  return (
    <div className="d-flex flex-column align-items-center">
      <ImagePreview
        src={media?.previewUrl}
        height={diameter}
        width={diameter}
        className="bg-white rounded-circle"
      />
      <ImageSelector
        setMedia={setMedia}
        trigger={(<Button className="btn-sm mt-3 px-3">
          {imageSelectorLabel}
        </Button>)}
      />
    </div>
  )
}


ProfilePhoto.propTypes = {
  media: mediaElementPropTypes,
  setMedia: PropTypes.func.isRequired,
  diameter: PropTypes.number.isRequired
}
