import React from 'react'
import PropTypes from 'prop-types'


const getArrayWithToggledValue = (array, value) => {
  if (array.includes(value)) {
    return array.filter(entry => entry !== value)
  }

  return [...array, value]
}


export const CheckboxGroup = ({ options = [], value = [], onChange, id = '', className = '', ...props }) => {

  const handleChange = e => {
    onChange(getArrayWithToggledValue(value, e.target.value))
  }


  return (
    <div className={className} {...props}>
      {
        options.map(({ value: optionValue, label }) => {
          const optionId = `chk-${id}-${optionValue}`

          return (
            <div className="form-check" key={optionValue}>
              <input
                checked={value.includes(optionValue)}
                className="form-check-input"
                id={optionId}
                name={id}
                onChange={handleChange}
                type="checkbox"
                value={optionValue}
              />
              <label className="form-check-label" htmlFor={optionId}>{label}</label>
            </div>
          )
        })
      }
    </div >
  )
}


CheckboxGroup.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  id: PropTypes.string.isRequired
}
