import React from 'react'
import { Link } from 'react-router-dom'

import { icons, Icon, IconButton } from './ui-blocks'


export const objectListRenderer = propertyName =>
  (row, column) =>
    row[column.field]
      .map(entry => entry[propertyName])
      .join(', ')


export const statusRenderer = (row, column) => (<>
  <small>
    <Icon
      className="text-muted text-left"
      style={{ width: '2em' }}
      icon={(row[column.field] === 'public') ? icons.public : icons.private}
    />
  </small>
  <span className="text-capitalize">{row[column.field]}</span>
</>)


export const tableActionsRenderer = actions =>
  row => (<>
    {actions.view &&
      <a href={actions.view(row)}
        className="link-dark faded"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconButton icon={icons.view} />
      </a>
    }
    {actions.edit &&
      <Link to={actions.edit(row)} className="link-dark faded">
        <IconButton icon={icons.edit} />
      </Link>
    }
    {actions.delete &&
      <IconButton
        icon={icons.delete}
        onClick={() => actions.delete(row)}
        className="danger-on-hover faded"
      />
    }
  </>)
