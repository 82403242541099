import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  faEye,
  faGlobe,
  faPencilAlt,
  faPlus,
  faPlusCircle,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUserLock
} from '@fortawesome/pro-light-svg-icons'

import {
  faExternalLink,
  faImage
} from '@fortawesome/pro-regular-svg-icons'

import {
  faBars,
  faChevronLeft,
  faGripLines,
  faSearch,
  faUserCircle,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons'

import {
  faBook,
  faBooks,
  faComments
} from '@fortawesome/pro-duotone-svg-icons'

import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faSnapchat,
  faTwitter,
  faWikipediaW
} from '@fortawesome/free-brands-svg-icons'


export const icons = {
  add: faPlus,
  addCircle: faPlusCircle,
  back: faChevronLeft,
  courses: faBooks,
  delete: faTrashAlt,
  edit: faPencilAlt,
  externalLink: faExternalLink,
  facebook: faFacebook,
  image: faImage,
  instagram: faInstagram,
  lessons: faBook,
  linkedin: faLinkedin,
  menu: faBars,
  private: faUserLock,
  public: faGlobe,
  remove: faTimes,
  removeCircle: faTimesCircle,
  search: faSearch,
  snapchat: faSnapchat,
  topics: faComments,
  twitter: faTwitter,
  userCircle: faUserCircle,
  verticalDragHandle: faGripLines,
  view: faEye,
  warningTriangle: faExclamationTriangle,
  wikipedia: faWikipediaW
}


export const Icon = ({ className = '', ...props }) => (
  <FontAwesomeIcon className={`icon ${className}`} {...props} />
)
