import React from 'react'
import PropTypes from 'prop-types'


export const Textarea = ({ error = false, isInvalid = false, isDirty = false, className = '', ...props }) => {
  let inputClasses = `form-control ${className}`

  if (error || isInvalid) {
    inputClasses += ' is-invalid'
  }

  if (!(error || isInvalid) && isDirty) {
    inputClasses += ' border-success'
  }

  return (
    <textarea className={inputClasses} {...props}></textarea>
  )
}


Textarea.propTypes = {
  /**
   * @deprecated in favor of isInvalid
   */
  error: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isDirty: PropTypes.bool
}
