import React from 'react'
import PropTypes from 'prop-types'


export const Select = ({
  options = [],
  value,
  isInvalid = false,
  isDirty = false,
  placeholder = '',
  className = '',
  ...props
}) => {

  let selectClasses = `form-select ${className}`

  if (isInvalid) {
    selectClasses += ' is-invalid'
  }

  if (!isInvalid && isDirty) {
    selectClasses += ' border-success'
  }


  return (
    <select className={selectClasses} value={value} {...props}>
      {!value && (
        <option>
          {placeholder}
        </option>
      )}
      {options.map(({ value: optionValue, label }) => (
        <option key={optionValue} value={optionValue}>
          {label}
        </option>
      ))}
    </select>
  )
}


Select.propTypes = {
  options: PropTypes.array.isRequired,
  isInvalid: PropTypes.bool,
  isDirty: PropTypes.bool,
  placeholder: PropTypes.string
}
