import React, { useEffect, useState } from 'react'

import { Loader } from '../components/ui-blocks'
import { getConfirmDelete } from '../components/specialized-components'

import { Lessons as LessonsComponent } from '../components/Lessons'

import { useModal } from '../contexts'

import { getLessons, deleteLesson } from '../helpers/consultdex-wp-api'


export const Lessons = () => {
  const [lessons, setLessons] = useState()
  const { openModal } = useModal()


  const loadLessons = () => {
    getLessons()
      .then(results => {
        setLessons(results)
      })
  }

  useEffect(loadLessons, [])


  const deleteAction = async row => {
    const isConfirmed = await openModal(
      getConfirmDelete(<>You will delete the lesson <strong>{row.title}</strong>.</>)
    )

    if (!isConfirmed) {
      return
    }

    await deleteLesson(row.id)

    loadLessons()
  }


  return (
    <>
      <h1>Lessons</h1>
      {!!lessons ? (
        <LessonsComponent lessons={lessons} deleteAction={deleteAction} />
      ) : (
          <Loader fullWidth />
        )}
    </>
  )
}
