import React from 'react'

import { ErrorBoundary } from './ErrorBoundary'

import { useModal } from '../../contexts'


export const ErrorHandler = ({ children }) => {
  const { openModal } = useModal()

  return (
    <ErrorBoundary openModal={openModal}>
      {children}
    </ErrorBoundary>
  )
}
