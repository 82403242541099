export default [
  {
    label: 'Courses',
    route: '/courses/'
  },
  {
    label: 'Lessons',
    route: '/lessons/'
  },
  {
    label: 'Topics',
    route: '/topics/'
  },
  {
    label: 'Consultant Profile',
    route: '/profile/'
  }
]
