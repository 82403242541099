/**
 * @param {string|number} startDate - Year
 * @param {string|number} endDate - Year
 * @return {string}
 */
export const getTimeframeLabel = (startDate, endDate = '') => {

  const currentYear = new Date().getFullYear()

  const startDateNumber = parseInt(startDate, 10)
  const endDateNumber = (endDate === '') ? currentYear : parseInt(endDate, 10)

  const yearsInvolved = endDateNumber - startDateNumber

  if (!Number.isInteger(yearsInvolved) || (yearsInvolved < 0)) {
    return ''
  }


  if (yearsInvolved === 0) {
    return '1 year'
  }

  const yearsLabel = (yearsInvolved === 1) ? 'year' : 'years'

  return `${yearsInvolved} ${yearsLabel}`
}
