import React, { useEffect, useState } from 'react'

import { Loader } from '../components/ui-blocks'

import { CourseCard } from '../components/Courses'


import { getCourses } from '../api-requests/learners'


export const LearnerCourses = () => {
  const [courses, setCourses] = useState()


  const loadCourses = () => {
    getCourses()
      .then(results => {
        setCourses(results)
      })
  }

  useEffect(loadCourses, [])


  return (
    <>
      <h1 className="mb-5">Courses</h1>
      {!!courses ? (
        <div className="row">
          {courses.map(course => (
            <div key={course.id} className="col-sm-6 col-xl-4 mb-3">
              <CourseCard course={course} />
            </div>
          ))}
        </div>
      )
        : (
          <Loader fullWidth />
        )}
    </>
  )
}
