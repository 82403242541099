import React from 'react'

import { getArrayWithoutEntry } from '../../../helpers'

import { SortableList } from '../../specialized-components'
import { icons, Icon, IconButton, Input, Button } from '../../ui-blocks'


export const ClientTypes = ({ clients, setClients }) => {

  const handleAddClient = () => {
    setClients([...clients, {}])
  }


  const handleDelete = id => {
    const updatedClients = getArrayWithoutEntry(clients, id)

    setClients(updatedClients)
  }


  const Client = ({
    item,
    dragHandleProps
  }) => (
      <div className={'pb-1 d-flex align-items-center'}>
        <div className="mr-1 px-1" {...dragHandleProps}>
          <Icon icon={icons.verticalDragHandle} />
        </div>
        <div className="d-flex flex-grow-1 align-items-center pr-1">
          <Input
            className="px-1 py-0"
            {...item.item.getStatusProps()}
            {...item.item.getProps()}
          />
        </div>
        <div className="ml-1 px-1">
          <IconButton
            icon={icons.removeCircle}
            onClick={() => handleDelete(item.id)}
            className="faded danger-on-hover"
          />
        </div>
      </div>
    )


  return (
    <>
      <SortableList
        id="clientList"
        items={clients}
        setItems={setClients}
        itemRenderer={Client}
      />
      <div className="d-flex justify-content-end mt-2">
        <Button onClick={handleAddClient} variant="secondary">
          <Icon icon={icons.add} />
          Add Client Type
        </Button>
      </div>
    </>
  )
}
