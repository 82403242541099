import React from 'react'


export class ErrorBoundary extends React.Component {

  state = { error: null }


  static getDerivedStateFromError(error) {
    return { error }
  }

  componentDidCatch(error, errorInfo) {
    this.props.openModal({ body: error.message })
  }

  render() {
    return !this.state.error && this.props.children
  }

}
