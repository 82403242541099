const isInteger = value => /^-?[0-9]*$/.test(value)


export const isRequired = value => (
  (value !== null)
  && (typeof value !== 'undefined')
  && (String(value).trim() !== '')
)


export const isZeroOrPositiveInteger = value => {
  if (!value) {
    return true
  }

  return (isInteger(value) && (Number(value) >= 0))
}


export const isIntegerBetween = (min, max) => value => {
  if (!value) {
    return true
  }

  return (isInteger(value) && (Number(value) >= min) && (Number(value) <= max))
}


export const isValidMail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}


export const isValidBasicUrl = str => {
  if (String(str).trim() === '') {
    return true
  }

  const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}' + // domain name
    '(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator

  return !!pattern.test(str)
}
