import React, { useLayoutEffect, useState } from 'react'
import { useHistory, useLocation, useParams, Link, Redirect } from 'react-router-dom'

import { EditTopic as EditTopicForm } from '../components/Topics'

import { useAsyncError } from '../components/error-handling'
import { getConfirmDelete } from '../components/specialized-components'
import { icons, Icon, Loader } from '../components/ui-blocks'

import { useModal } from '../contexts'

import { getById, getParsedFormData } from '../helpers'
import { getTopic, createTopic, updateTopic, deleteTopic } from '../helpers/consultdex-wp-api'


export const EditTopic = () => {
  const id = Number(useParams().id)

  const [editingCourse, setEditingCourse] = useState(null)
  const [topic, setTopic] = useState(null)
  const { openModal } = useModal()
  const history = useHistory()
  const location = useLocation()

  const handleAsyncError = useAsyncError()


  useLayoutEffect(() => {
    if (!id) {
      setTopic({})
      return
    }

    getTopic(id)
      .then(results => {
        setTopic(results)

        const courseId = Number(new URLSearchParams(location.search).get('courseId'))
        setEditingCourse(getById(courseId, results.courses))

      }, handleAsyncError)
  }, [id, handleAsyncError, location.search])


  const handleUpdate = async values => {
    if (topic?.id) {
      setTopic(await updateTopic(topic?.id, getParsedFormData(values)))
      return
    }

    const createdTopic = await createTopic(getParsedFormData(values))
    setTopic(createdTopic)
  }


  if (topic?.id && (id !== topic.id)) {
    return (<Redirect to={`/topics/${topic.id}`} />)
  }


  const handleDelete = async () => {
    if (await openModal(
      getConfirmDelete(<>You will delete the topic <strong>{topic.title}</strong>.</>)
    )) {
      await deleteTopic(topic.id)

      history.push('/topics/')
    }
  }


  return (
    <>
      {editingCourse ? (
        <Link to={`/courses/${editingCourse.id}`} className="text-dark mb-3 d-inline-block underline-on-hover">
          <Icon icon={icons.back} className="mr-2" /><strong>{editingCourse.title}</strong> Course
        </Link>
      ) : (
        <Link to="/topics/" className="text-dark mb-3 d-inline-block underline-on-hover">
          <Icon icon={icons.back} className="mr-2" />Back to Topics
        </Link>
      )}

      {topic ?
        <EditTopicForm
          topic={topic}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
        />
        : <Loader fullWidth />
      }
    </>
  )

}
