import React from 'react'
import PropTypes from 'prop-types'

import { icons, Dropdown, Icon } from '../ui-blocks'


const AvatarImage = ({ imageUrl }) => {
  if (!imageUrl) {
    return <Icon icon={icons.userCircle} className="text-primary bg-white rounded-circle display-6" />
  }

  return <img src={imageUrl} className="bg-white rounded-circle" alt="Your Profile" />
}


export const AvatarMenu = ({ avatarProps, role, menuEntries, handleLogout }) => {

  const avatarSnippet = (
    <div className="avatar-menu-snippet d-flex align-items-center py-1 px-2 rounded-sm">
      <div className="pr-2">
        <AvatarImage imageUrl={avatarProps?.imageUrl} />
      </div>
      <div className="pr-2">
        <p className="font-weight-bold mb-0">{avatarProps?.name}</p>
        {(role !== 'learner') && (<span className="small text-muted text-capitalize">{role}</span>)}
      </div>
      <div className="dropdown-toggle">
      </div>
    </div>
  )


  return (
    <Dropdown trigger={avatarSnippet} closeOnClick={true} className="avatar-menu" dropdownContentClassName="w-100">
      <ul className="list-unstyled mb-0">
        {!!menuEntries && (
          <>
            {menuEntries}
            <div className="dropdown-divider"></div>
          </>
        )}
        <li>
          <button type="button" className="dropdown-item" onClick={handleLogout}>
            Log out
          </button>
        </li>
      </ul>
    </Dropdown>
  )
}


AvatarMenu.propTypes = {
  avatarProps: PropTypes.object,
  role: PropTypes.string,
  menuEntries: PropTypes.node,
  handleLogout: PropTypes.func.isRequired
}
